import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  Dependency,
  RunExecution,
  SettingsData,
  TestFolder,
  TestTemplate,
  UnparsedTest,
} from "../../utils/types";
import { AuthContext } from "../../auth/AuthContext";
import { buildDialogStateAtom, SelectBuildDialog } from "./select-build-dialog";
import getBuildsAtom from "../../atoms/get-builds-atom";
import GenericDatatable from "../../components/generic-datatable";
import { getTestsAtom, TestListData } from "../../atoms/testing-settings-atoms";
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridComparatorFn,
  gridFilteredSortedRowEntriesSelector,
  gridQuickFilterValuesSelector,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  useGridApiContext,
  useGridApiRef,
} from "@mui/x-data-grid";
import { DateTime } from "luxon";
import { GridInitialStateCommunity } from "@mui/x-data-grid/models/gridStateCommunity";

import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  arrayUnion,
  collection,
  doc,
  documentId,
  getDocs,
  orderBy,
  query,
  runTransaction,
  updateDoc,
  where,
} from "firebase/firestore";
import { customFirestore } from "../../firebase";
import { arrayRemove } from "@firebase/firestore";
import {
  getErrorMessage,
  removeItemFromArray,
  replaceItemFomArray,
  trackSentryException,
} from "../../utils/helpers";
import { customAlertStateAtom } from "../../components/custom-alert";
import Button from "@mui/material/Button";
import {
  Add,
  ChevronLeft,
  Delete,
  DeleteOutline,
  Menu,
  SnippetFolderOutlined,
} from "@mui/icons-material";
import { BACKEND_SERVER_URL } from "../../constants/aws-constants";
import Box from "@mui/material/Box";
import { IconButton, Popover, Tooltip, Typography } from "@mui/material";
import axios, { AxiosError } from "axios";
import { GridCallbackDetails } from "@mui/x-data-grid/models/api";
import { GridFilterModel } from "@mui/x-data-grid/models/gridFilterModel";
import TestsTreeView, {
  foldersStateAtom,
  kAllTestsId,
} from "./tests-tree-view";
import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/material/styles";
import { AsyncConfirmationModal } from "../../components/asyncConfirmationModal";
import MoveToFolderDialog, {
  getSubFolders,
  moveToFolderStateAtom,
} from "./move-to-folder-dialog";
import { companyDataAtom } from "../test-run/test-run-atoms";
import { atomWithStorage } from "jotai/utils";

const TestsHome = () => {
  const { user } = useContext<any>(AuthContext);

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );
  const companyDataQuery = useAtomValue(companyDataAtom(user.companyId));
  const allBuildsQuery = useAtomValue(
    getBuildsAtom({ organizationId: user.companyId, limit: 20 })
  );
  const setBuildDialogState = useSetAtom(buildDialogStateAtom);

  const handleNewTest = useCallback(() => {
    setBuildDialogState((prev) => ({
      ...prev,
      open: true,
      actionTitle: "Add new test",
      handleConfirm: async (buildId) => {
        navigateTo(`/gpt-driver/build/${buildId}`);
      },
    }));
  }, []);

  const [expandFolders, setExpandFolders] = useAtom(expandFoldersAtom);

  const enableFolders =
    companyDataQuery.data?.settings?.enableFoldersFeature ?? false;
  return (
    <ContentWrapper enableFolders={enableFolders} expandFolders={expandFolders}>
      <SelectBuildDialog />
      <TitleWrapper>
        <PageTitle>Tests</PageTitle>
        <AddTestButton
          title={
            allBuildsQuery?.data?.length === 0
              ? "Please upload a build file first"
              : ""
          }
        >
          <span>
            <Button
              variant={"outlined"}
              onClick={handleNewTest}
              startIcon={<Add />}
              disabled={
                allBuildsQuery?.data?.length === 0 ||
                allBuildsQuery.isLoading ||
                allBuildsQuery.isError
              }
            >
              Add new test
            </Button>
          </span>
        </AddTestButton>
      </TitleWrapper>
      {enableFolders && (
        <FoldersWrapper expandFolders={expandFolders}>
          <ExpandFolderIcon
            onClick={() => {
              setExpandFolders((prev) => !prev);
            }}
            expandFolders={expandFolders}
          >
            {expandFolders ? <ChevronLeft /> : <Menu />}
          </ExpandFolderIcon>
          <TestsTreeView expandFolders={expandFolders} />
        </FoldersWrapper>
      )}

      <TableWrapper>
        {!companyDataQuery.isLoading && !companyDataQuery.isError && (
          <DatatableWrapper settingsData={companyDataQuery.data.settings} />
        )}
      </TableWrapper>
    </ContentWrapper>
  );
};

const ExpandFolderIcon = styled(IconButton)(
  ({ expandFolders }: { expandFolders: boolean }) => ({
    justifySelf: expandFolders ? "end" : "start",
  })
);

const ContentWrapper = styled.div(
  ({
    enableFolders,
    expandFolders,
  }: {
    enableFolders: boolean;
    expandFolders: boolean;
  }) => ({
    gridArea: "content",
    display: "grid",
    transition: "200ms",
    transitionDelay: !expandFolders ? "100ms" : "0ms",
    ...(enableFolders
      ? {
          gridTemplateAreas: `
    "title-wrapper title-wrapper"
    "folders datatable"
  `,
        }
      : {
          gridTemplateAreas: `
    "title-wrapper title-wrapper"
    "datatable datatable"
  `,
        }),
    columnGap: "12px",
    gridTemplateColumns: expandFolders ? "288px 1fr" : "40px 1fr",
    gridTemplateRows: "80px calc(100vh - 205px)",
    backgroundColor: "#e8e8e8",
    padding: "16px",
    borderRadius: "8px",
    margin: "16px",
  })
);

const TitleWrapper = styled.div`
  grid-area: title-wrapper;
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled.p`
  font-size: 24px;
  font-weight: 500;
`;

const FoldersWrapper = styled.div(
  ({ expandFolders }: { expandFolders: boolean }) => ({
    gridArea: "folders",
    display: "grid",
    gridTemplateAreas: expandFolders
      ? `"expand-icon"
    "folders"`
      : `"expand-icon"`,
    gridTemplateRows: expandFolders ? "40px minmax(0, 1fr)" : "40px",
    rowGap: "4px",
  })
);

const TableWrapper = styled.div`
  grid-area: datatable;
  overflow: auto;
`;

const AddTestButton = muiStyled(Tooltip)(() => ({
  gridArea: "actions",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-start",
}));

const DatatableWrapper = ({ settingsData }: { settingsData: SettingsData }) => {
  const apiRef = useGridApiRef();

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string, match?: string) => {
      if (match != null) {
        window.open(`${path}?match=${encodeURIComponent(match)}`, "_blank");
      } else {
        navigate(path);
      }
    },
    [navigate]
  );

  const { user } = useContext<any>(AuthContext);
  const foldersState = useAtomValue(foldersStateAtom);

  const testListQuery = useAtomValue(
    getTestsAtom({
      organizationId: user.companyId,
      folderId: foldersState.selectedFolderId,
    })
  );

  useEffect(() => {
    if (testListQuery.data != null) {
      apiRef.current.setRowSelectionModel(
        foldersState.selectedTestId != null ? [foldersState.selectedTestId] : []
      );
    }
  }, [
    foldersState.selectedTestId,
    foldersState.selectedFolderId,
    testListQuery.data,
  ]);

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteTestDialogState, setDeleteTestDialogState] =
    useState<TestTemplate | null>(null);
  const setBuildDialogState = useSetAtom(buildDialogStateAtom);

  const handleDeleteTest = useCallback((testTemplate: TestTemplate) => {
    setDeleteTestDialogState(testTemplate);
  }, []);

  const resetDeleteTestDialogState = useCallback(() => {
    setDeleteTestDialogState(null);
  }, []);

  const handleEditTest = useCallback((testId: string, match?: string) => {
    setBuildDialogState((prev) => ({
      ...prev,
      open: true,
      actionTitle: "Edit test",
      handleConfirm: async (buildId) => {
        navigateTo(`/gpt-driver/build/${buildId}/test/${testId}`, match);
      },
    }));
  }, []);

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const queryClient = useQueryClient();

  const deleteTestMutation = useMutation({
    mutationFn: async () => {
      const testsRef = collection(customFirestore, "custom-tests");
      const testId = deleteTestDialogState!.id;
      const q = query(
        testsRef,
        where("organisationId", "==", user.companyId),
        where(documentId(), "!=", testId),
        orderBy(documentId(), "asc")
      );
      const querySnapshot = await getDocs(q);
      const testWithDependencies: (UnparsedTest & {
        dependencies: Dependency[];
      })[] = await Promise.all(
        querySnapshot.docs.map(async (test) => {
          let unparsedTest = test.data() as UnparsedTest;
          let customParsedTest: UnparsedTest & {
            dependencies: Dependency[];
          } = { ...unparsedTest, dependencies: [] };

          customParsedTest.id = test.id;

          // Access sub-collection and add to test data
          const subCollectionRef = collection(test.ref, "dependencies");
          const subCollectionSnapshot = await getDocs(subCollectionRef);

          customParsedTest.dependencies = subCollectionSnapshot.docs.map(
            (doc) => doc.data() as Dependency
          );

          return customParsedTest;
        })
      );

      const dependentTests = testWithDependencies.reduce<UnparsedTest[]>(
        (prev, currentTest) => {
          const directDependencies =
            currentTest.dependencies?.filter(
              (dependency) => dependency.testId === testId
            ) ?? [];
          if (directDependencies.length > 0) {
            return [...prev, currentTest];
          }
          return prev;
        },
        []
      );

      if (dependentTests.length > 0) {
        setCustomAlertState({
          open: true,
          type: "error",
          title: "Test couldn't be deleted, the following tests depend on it:",
          listContent: dependentTests
            .slice(0, 6)
            .map((dependentTest) => dependentTest.title),
        });
      } else {
        const testBelongedToFolderId = await runTransaction(
          customFirestore,
          async (transaction) => {
            const testRef = doc(customFirestore, "custom-tests", testId);
            const testSnap = await transaction.get(testRef);
            const testData = testSnap.data() as UnparsedTest;
            if (testData.folderId != null) {
              const folderRef = doc(
                customFirestore,
                "folders",
                testData.folderId
              );
              const folderSnap = await transaction.get(folderRef);
              const folder = folderSnap.data() as TestFolder;
              const testToRemove = folder.tests?.find(
                (test) => test.id === testId
              );
              if (!!testToRemove) {
                transaction.update(folderRef, {
                  tests: arrayRemove(testToRemove),
                });
              }
            }
            transaction.delete(testRef);
            return testData.folderId;
          }
        );
        queryClient.removeQueries({
          queryKey: ["getTests"],
        });
        await queryClient.invalidateQueries({
          queryKey: ["getFolders"],
        });
        getTestsAtom.remove({
          organizationId: user.companyId,
          folderId: kAllTestsId,
        });
        if (!!testBelongedToFolderId) {
          getTestsAtom.remove({
            organizationId: user.companyId,
            folderId: testBelongedToFolderId,
          });
        }

        setCustomAlertState({
          open: true,
          type: "success",
          title: "Test has been deleted successfully",
        });
      }
    },
    onError: (error) => {
      console.error("Error deleting", error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: `${error}`,
      });
    },
    onSettled: () => {
      resetDeleteTestDialogState();
    },
  });

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      createdAt: false,
      parsedCommands: false,
      runHistory: settingsData.runHistoryEnabled ?? false,
    });

  const onFilterModelChange: (
    model: GridFilterModel,
    details: GridCallbackDetails<"filter">
  ) => void = (model, details) => {
    const filteredItems = gridFilteredSortedRowEntriesSelector(apiRef);
    const searchText = gridQuickFilterValuesSelector(apiRef)!
      .filter((word) => word !== "")
      .join(" ");

    const newCommandsVisibility =
      searchText.length > 0 &&
      filteredItems.some((filteredItem) =>
        (filteredItem.model.parsedCommands ?? filteredItem.model.commands)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      );

    if (filteredItems.length > 0) {
      setColumnVisibilityModel?.((prev) => ({
        ...prev,
        parsedCommands: newCommandsVisibility,
      }));
    }
  };

  const tableConfig = useMemo(
    () => ({
      columns: [
        {
          field: "title",
          headerName: "Title",
          sortable: true,
          flex: 1,
          renderCell: (params: GridRenderCellParams<TestTemplate>) => {
            const row = params.row;

            const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(
              null
            );

            const handlePopoverOpen = (
              event: React.MouseEvent<HTMLElement>
            ) => {
              setAnchorEl(event.currentTarget);
            };

            const handlePopoverClose = () => {
              setAnchorEl(null);
            };

            const open = Boolean(anchorEl);

            const foldersState = useAtomValue(foldersStateAtom);

            const folderPath = useMemo(() => {
              if (
                row.folderId != null &&
                foldersState.selectedFolderId === kAllTestsId
              ) {
                const allFolders = getSubFolders(foldersState.folders!);
                return allFolders.find((folder) => folder.id === row.folderId)
                  ?.path;
              }
              return undefined;
            }, [row.folderId, foldersState.folders]);

            return (
              <div
                className={
                  "flex gap-2 w-full h-full hover:cursor-pointer items-center"
                }
                onClick={() => handleEditTest(row.id)}
              >
                <span className={"flex-1 overflow-ellipsis"}>
                  {params.value}
                </span>
                {row.folderId != null &&
                  foldersState.selectedFolderId === kAllTestsId && (
                    <div
                      style={{
                        width: "3ch",
                        flexShrink: 0,
                      }}
                    >
                      <SnippetFolderOutlined
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      />
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <Typography variant={"body2"} sx={{ p: 1 }}>
                          {folderPath}
                        </Typography>
                      </Popover>
                    </div>
                  )}
              </div>
            );
          },
        },
        {
          field: "parsedCommands",
          headerName: "Commands",
          width: 240,
          valueGetter: (value, row) => {
            return row;
          },
          getApplyQuickFilterFn: (value) => {
            return (cellValue) => {
              const searchText = gridQuickFilterValuesSelector(apiRef)!
                .filter((word) => word !== "")
                .join(" ");

              const fullText = cellValue.parsedCommands ?? cellValue.commands;

              return fullText.toLowerCase().includes(searchText.toLowerCase());
            };
          },
          renderCell: (params: GridRenderCellParams<TestTemplate>) => {
            const row = params.row;

            const searchText = gridQuickFilterValuesSelector(apiRef)!
              .filter((word) => word !== "")
              .join(" ");

            const fullText = row.parsedCommands ?? row.commands;

            const indexOfMatch = fullText
              .toLowerCase()
              .indexOf(searchText.toLowerCase());
            if (indexOfMatch == -1) {
              return <div> - </div>;
            } else {
              const match = fullText.substring(
                indexOfMatch,
                indexOfMatch + searchText.length
              );

              return (
                <div className={"w-full overflow-x-auto"}>
                  <Tooltip title={"Edit selected"}>
                    <span
                      onClick={() => handleEditTest(row.id, match)}
                      className={"bg-amber-500  hover:cursor-pointer"}
                    >
                      {match}
                    </span>
                  </Tooltip>
                  <span>
                    {fullText.substring(indexOfMatch + searchText.length)}
                  </span>
                </div>
              );
            }
          },
        },
        {
          field: "tags",
          headerName: "Tags",
          sortable: true,
          width: 240,
          sortComparator: tagsComparator,
          renderCell: (params: GridRenderCellParams<TestTemplate>) => {
            return <TagsCell params={params} />;
          },
        },
        {
          field: "runHistory",
          headerName: "Run History",
          sortable: true,
          hideable: true,
          width: 158,
          sortComparator: runHistoryComparator,
          renderCell: (params: GridRenderCellParams<TestTemplate>) => {
            return (
              params.value.length > 0 && (
                <RunHistoryCell runHistory={params.value} />
              )
            );
          },
        },
        {
          field: "createdAt",
          headerName: "Last edited at",
          sortable: true,
          hideable: true,
          width: 128,
          valueGetter: (value) => DateTime.fromJSDate(value.toDate()),
          renderCell: (params: GridRenderCellParams<TestTemplate>) => {
            return (
              <div className={"flex justify-center items-center h-full"}>
                <Tooltip title={params.value.toFormat("yyyy-MM-dd hh:mm a")}>
                  <p className={"text-xs"}>{params.value.toRelative()}</p>
                </Tooltip>
              </div>
            );
          },
        },
        {
          field: "actions",
          type: "actions",
          width: 56,
          getActions: ({ row }: { row: TestTemplate }) => [
            <GridActionsCellItem
              icon={<DeleteOutline />}
              label="Delete"
              onClick={() => handleDeleteTest(row)}
              color="inherit"
            />,
          ],
        },
      ] as GridColDef[],
      initialState: {
        sorting: {
          sortModel: [{ field: "title", sort: "asc" }],
        },
      } as GridInitialStateCommunity,
    }),
    []
  );

  const [displayUncategorizedOnly, setDisplayUncategorizedOnly] =
    useState(false);

  const filteredData = useMemo(() => {
    if (foldersState.selectedFolderId !== kAllTestsId) {
      return testListQuery?.data?.values;
    }

    return displayUncategorizedOnly
      ? testListQuery?.data?.values.filter((item) => item.folderId == null)
      : testListQuery?.data?.values;
  }, [
    testListQuery?.data?.values,
    foldersState.selectedFolderId,
    displayUncategorizedOnly,
  ]);

  return (
    <>
      <MoveToFolderDialog />
      <AsyncConfirmationModal
        title={"Are you sure you want to delete the test?\n"}
        actionTitle={"Delete"}
        backTitle={"Close"}
        closeHandler={resetDeleteTestDialogState}
        description={`Test: ${deleteTestDialogState?.title}`}
        open={deleteTestDialogState != null}
        actionHandler={deleteTestMutation.mutateAsync}
        actionIcon={<Delete />}
        submitting={deleteTestMutation.isPending}
      />
      <GenericDatatable
        apiRef={apiRef}
        query={testListQuery}
        onFilterModelChange={onFilterModelChange}
        data={filteredData}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        columns={tableConfig.columns}
        initialState={tableConfig.initialState}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        toolbarActions={ToolbarActions}
        enableFolders={settingsData.enableFoldersFeature}
        noRowsTitle={"No tests defined yet"}
        setDisplayUncategorizedOnly={setDisplayUncategorizedOnly}
        displayUncategorizedOnly={displayUncategorizedOnly}
      />
    </>
  );
};

const ToolbarActions = ({
  rowSelectionModel,
  setRowSelectionModel,
  enableFolders,
  displayUncategorizedOnly,
  setDisplayUncategorizedOnly,
}: {
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  enableFolders: boolean;
  displayUncategorizedOnly: boolean;
  setDisplayUncategorizedOnly: Dispatch<SetStateAction<boolean>>;
}) => (
  <Box
    sx={{
      paddingInline: "16px",
      paddingTop: 1.5,
      paddingBottom: 1.5,
      display: "flex",
      justifyContent: "flex-end",
      borderBottomColor: "rgb(238, 238, 238)",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      alignItems: "center",
      position: "relative",
    }}
  >
    <GridToolbarQuickFilter
      sx={{
        position: "absolute",
        top: "15px",
        left: "16px",
      }}
      debounceMs={500}
    />
    <TestTriggers
      rowSelectionModel={rowSelectionModel}
      setRowSelectionModel={setRowSelectionModel}
      enableFolders={enableFolders}
      displayUncategorizedOnly={displayUncategorizedOnly}
      setDisplayUncategorizedOnly={setDisplayUncategorizedOnly}
    />
  </Box>
);
const TestTriggers = ({
  rowSelectionModel,
  setRowSelectionModel,
  enableFolders,
  setDisplayUncategorizedOnly,
  displayUncategorizedOnly,
}: {
  rowSelectionModel: GridRowSelectionModel;
  setRowSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  enableFolders: boolean;
  displayUncategorizedOnly: boolean;
  setDisplayUncategorizedOnly: Dispatch<SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const setBuildDialogState = useSetAtom(buildDialogStateAtom);
  const useAuth = () => useContext<any>(AuthContext);
  const { user } = useAuth();
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const runTests = useCallback(
    (multipleInteractions: boolean) => {
      setBuildDialogState((prev) => ({
        ...prev,
        open: true,
        actionTitle: `Run test${rowSelectionModel.length > 1 ? "s" : ""}`,
        submittingTitle: `Starting test${
          rowSelectionModel.length > 1 ? "s" : ""
        }...`,
        handleConfirm: async (buildId) => {
          try {
            const url = `${BACKEND_SERVER_URL}/createSuite`;
            console.log("calling: ", url);

            const body = {
              organisationId: user.companyId,
              uploadId: buildId,
              customTestIds: rowSelectionModel,
              ...(multipleInteractions && {
                iterations: 3,
              }),
              trigger: "web",
            };

            await axios(url, {
              method: "POST",
              data: body,
              timeout: 30000,
            });

            navigateTo(`/gpt-driver/reports`);
          } catch (error) {
            trackSentryException(error);
            let errorDescription = getErrorMessage(
              `Could not start running ${
                rowSelectionModel.length > 1 ? "tests" : "test"
              }`
            );

            if (
              axios.isAxiosError(error) &&
              (error as AxiosError)?.response?.status === 429
            ) {
              errorDescription =
                "You have too many cloud runs in process currently and therefore cannot start another one. Please try again once another cloud run has finished.";
            }

            setCustomAlertState({
              open: true,
              type: "error",
              title: "Something went wrong",
              description: errorDescription,
            });

            console.error("Error:", error);
          }
          setRowSelectionModel([]);
        },
      }));
    },
    [rowSelectionModel, setRowSelectionModel, user]
  );

  const apiRef = useGridApiContext();
  const setMoveToFolderState = useSetAtom(moveToFolderStateAtom);

  const handleMoveToFolder = useCallback(() => {
    const selectedRows = Array.from(
      apiRef.current.getSelectedRows().values()
    ) as TestTemplate[];
    setMoveToFolderState(selectedRows);
  }, [setMoveToFolderState]);

  const foldersState = useAtomValue(foldersStateAtom);

  const handleFilterUncategorized = useCallback(() => {
    setDisplayUncategorizedOnly((prev) => !prev);
  }, [setDisplayUncategorizedOnly]);

  return (
    <div className={"flex gap-2"}>
      {/*{enableFolders && (*/}
      {/*  <>*/}
      {/*    {foldersState.selectedFolderId == kAllTestsId &&*/}
      {/*      ((!displayUncategorizedOnly && apiRef.current.getRowsCount() > 0) ||*/}
      {/*        displayUncategorizedOnly) && (*/}
      {/*        <Button*/}
      {/*          variant={"outlined"}*/}
      {/*          endIcon={*/}
      {/*            displayUncategorizedOnly ? <FilterAltOff /> : <FilterAlt />*/}
      {/*          }*/}
      {/*          onClick={handleFilterUncategorized}*/}
      {/*        >*/}
      {/*          {displayUncategorizedOnly ? "Show all" : "Hide categorized"}*/}
      {/*        </Button>*/}
      {/*      )}*/}
      {/*  </>*/}
      {/*)}*/}
      <Button
        variant={"outlined"}
        disabled={
          rowSelectionModel.length === 0 || foldersState.folders?.length == 1
        }
        onClick={handleMoveToFolder}
      >{`Move test${rowSelectionModel.length > 1 ? "s" : ""}`}</Button>
      <Button
        disabled={rowSelectionModel.length === 0}
        variant={"contained"}
        onClick={() => runTests(false)}
      >
        {`Run test${rowSelectionModel.length > 1 ? "s" : ""}`}
      </Button>
      {user.role === "superUser" && (
        <Button
          disabled={rowSelectionModel.length === 0}
          variant={"contained"}
          onClick={() => runTests(true)}
        >
          Run tests x3
        </Button>
      )}
    </div>
  );
};

const RunHistoryCell = ({ runHistory }: { runHistory: RunExecution[] }) => {
  const backgroundColorMap = (status: string) => {
    switch (status) {
      case "failed":
        return "bg-red-400";
      case "blocked":
        return "bg-yellow-400";
      case "succeeded":
        return "bg-green-400";
    }
  };

  const timestampToDate = (timestamp: number) => {
    return DateTime.fromJSDate(new Date(timestamp * 1000)).toFormat(
      "dd-MM-yyyy hh:mm a"
    );
  };

  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <div className="flex gap-x-1 h-full items-center">
      {runHistory.map((run, index) => (
        <Tooltip
          title={`${capitalize(run.status)} - ${timestampToDate(
            run.finishedTimeInEpoch
          )}`}
          key={index}
        >
          <a
            href={`/recording/${run.suite_ref}/runs/${run.run_ref}`}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className={`${backgroundColorMap(
                run.status
              )} w-2.5 h-7 rounded-md`}
            />
          </a>
        </Tooltip>
      ))}
    </div>
  );
};

const TagsCell = ({
  params,
}: {
  params: GridRenderCellParams<TestTemplate>;
}) => {
  const queryClient = useQueryClient();
  const tags = useMemo(() => params.value ?? [], [params.value]);
  const { user } = useContext<any>(AuthContext);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const foldersState = useAtomValue(foldersStateAtom);

  const testListQuery = useAtomValue(
    getTestsAtom({
      organizationId: user.companyId,
      folderId: foldersState.selectedFolderId,
    })
  );

  const handleAddTag = useCallback(
    ({ testId, tag }: { testId: string; tag: string }) => {
      if (tags.includes(tag)) {
        setCustomAlertState({
          open: true,
          type: "error",
          title: "Something went wrong",
          description: getErrorMessage("The tag is already set to the test"),
        });
      } else {
        addTagMutation.mutate({ testId, tag });
      }
    },
    [tags]
  );

  const deleteTagMutation = useMutation({
    mutationFn: async ({ testId, tag }: { testId: string; tag: string }) => {
      const testRef = doc(customFirestore, "custom-tests", testId);
      await updateDoc(testRef, {
        tags: arrayRemove(tag),
      });
    },
    onMutate: async ({ tag }: { tag: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["getTests", user.companyId, foldersState.selectedFolderId],
      });

      // Snapshot the previous value
      const previousTests = queryClient.getQueryData<TestListData>([
        "getTests",
        user.companyId,
        foldersState.selectedFolderId,
      ])!;

      const previousTestsValues = previousTests.values;

      const previousTestIndex = previousTestsValues.findIndex(
        (test) => test.id == params.row.id
      );
      const previousTest = previousTestsValues![previousTestIndex];

      const tagIndexToDelete = previousTest.tags.indexOf(tag);
      const updatedTest = {
        ...previousTest,
        tags: removeItemFromArray(previousTest.tags, tagIndexToDelete),
      };

      queryClient.setQueryData<TestListData>(
        ["getTests", user.companyId, foldersState.selectedFolderId],
        {
          values: replaceItemFomArray(
            previousTestsValues,
            previousTestIndex,
            updatedTest
          ),
          availableTags: previousTests.availableTags,
        }
      );

      return { previousTests };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        ["getTests", user.companyId, foldersState.selectedFolderId],
        context?.previousTests ?? { values: [], availableTags: [] }
      );

      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: getErrorMessage(error),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTests", user.companyId, foldersState.selectedFolderId],
      });
    },
  });

  const addTagMutation = useMutation({
    mutationFn: async ({ testId, tag }: { testId: string; tag: string }) => {
      const testRef = doc(customFirestore, "custom-tests", testId);
      await updateDoc(testRef, {
        tags: arrayUnion(tag),
      });
    },
    onMutate: async ({ tag }: { tag: string }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["getTests", user.companyId, foldersState.selectedFolderId],
      });

      // Snapshot the previous value
      const previousTests = queryClient.getQueryData<TestListData>([
        "getTests",
        user.companyId,
        foldersState.selectedFolderId,
      ]);

      const previousTestsValues = previousTests!.values;

      const previousTestIndex = previousTestsValues.findIndex(
        (test) => test.id == params.row.id
      );
      const previousTest = previousTestsValues![previousTestIndex];

      const updatedTest = {
        ...previousTest,
        tags: [...(previousTest.tags ?? []), tag],
      };

      const newAvailableTags = previousTests!.availableTags.includes(tag)
        ? previousTests!.availableTags
        : [...previousTests!.availableTags, tag];

      queryClient.setQueryData<TestListData>(
        ["getTests", user.companyId, foldersState.selectedFolderId],
        {
          values: replaceItemFomArray(
            previousTestsValues!,
            previousTestIndex,
            updatedTest
          ),
          availableTags: newAvailableTags,
        }
      );

      return { previousTests };
    },
    onError: (error, _, context) => {
      queryClient.setQueryData(
        ["getTests", user.companyId, foldersState.selectedFolderId],
        context?.previousTests ?? []
      );

      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: getErrorMessage(error),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTests", user.companyId, foldersState.selectedFolderId],
      });
    },
  });

  const options = useMemo(
    () => (testListQuery.data?.availableTags ?? []).sort(),
    [testListQuery.data]
  );

  return (
    <div className={"overflow-auto w-full h-full flex flex-col justify-center"}>
      {tags && (
        <Autocomplete
          multiple
          id="tags-filled"
          className={"bg-white"}
          options={options}
          value={tags}
          defaultValue={tags}
          freeSolo
          onChange={(e, newValues) => {
            if (newValues.length > tags.length) {
              const tagToAdd = newValues.filter(
                (newValue) => !tags.includes(newValue)
              )[0];
              handleAddTag({
                testId: params.row.id,
                tag: tagToAdd,
              });
            } else {
              const tagToDelete = tags.filter(
                (tag) => !newValues.includes(tag)
              )[0];
              deleteTagMutation.mutate({
                testId: params.row.id,
                tag: tagToDelete,
              });
            }
          }}
          onKeyDown={(event) => {
            if (event.code === "Space") {
              event.stopPropagation();
            }
          }}
          disableClearable={true}
          sx={{
            backgroundColor: "transparent",
            "& .MuiDataGrid-cell": {
              borderColor: "transparent",
            },
            "& .MuiInputBase-root": {
              backgroundColor: "transparent",
              padding: 0,
              ":after": {
                borderBottom: "none",
              },
              ":before": {
                borderBottom: "none",
              },
              ":hover": {
                backgroundColor: "transparent",
                ":after": {
                  borderBottom: "none",
                },
                ":before": {
                  borderBottom: "none",
                },
                ":not(.Mui-disabled, .Mui-error):before": {
                  borderBottom: "none",
                },
              },
            },
            "& .MuiTextField-root": {
              height: "100%",
            },
            "& .MuiAutocomplete-inputRoot": {
              flexWrap: "nowrap",
              marginBlock: "auto",
            },
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                variant="outlined"
                label={option}
                color={"primary"}
                {...getTagProps({ index })}
                onDelete={() =>
                  deleteTagMutation.mutate({
                    testId: params.row.id,
                    tag: option,
                  })
                }
              />
            ))
          }
          renderInput={(params) => <TextField {...params} variant="filled" />}
        />
      )}
    </div>
  );
};

function getAsciiSum(str) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }
  return sum;
}

const tagsComparator: GridComparatorFn<string[]> = (
  listA: string[] | null | undefined,
  listB: string[] | null | undefined
): number => {
  // If listA or listB is null or undefined, handle those cases first
  if (listA == null && listB != null) return -1;
  if (listB == null && listA != null) return 1;
  if (listA == null && listB == null) return 0;

  if (listA!.length === listB!.length) {
    return (
      listA!.reduce((prev, current) => prev + getAsciiSum(current), 0) -
      listB!.reduce((prev, current) => prev + getAsciiSum(current), 0)
    );
  }
  return listA!.length - listB!.length;
};

const runHistoryComparator: GridComparatorFn<RunExecution[]> = (
  listA: RunExecution[] | null | undefined,
  listB: RunExecution[] | null | undefined
): number => {
  const statusScore = (status: string) => {
    switch (status) {
      case "succeeded":
        return 1;
      case "blocked":
        return 0;
      case "failed":
        return -1;
      default:
        return 0;
    }
  };

  // If listA or listB is empty, handle those cases first
  if (listA!.length == 0 && listB!.length > 0) return -1;
  if (listB!.length == 0 && listA!.length > 0) return 1;
  if (listA!.length == 0 && listB!.length == 0) return 0;

  // Compare based on the score of the inner lists. Scores for each test runs are:
  // +1 for succeeded test, 0 for blocked test and -1 for failed test
  const listAScores: number[] = listA!.map((runExecution) =>
    statusScore(runExecution.status)
  );
  const listBScores: number[] = listB!.map((runExecution) =>
    statusScore(runExecution.status)
  );

  const scoreA = listAScores.reduce((partialSum, a) => partialSum + a, 0);
  const scoreB = listBScores.reduce((partialSum, a) => partialSum + a, 0);

  return scoreA - scoreB;
};

const expandFoldersAtom = atomWithStorage("expandFolders", true);

export default TestsHome;
