import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { Editor } from "@tiptap/core";

import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import StarterKit from "@tiptap/starter-kit";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  Close,
  FormatListNumbered,
  PlayArrow,
  Stop,
  History,
  Undo,
  Redo,
  VideoLibrary,
  Help,
  Abc,
  SwipeUp,
  Check,
  Link,
  HourglassBottom,
  CalendarToday,
  DataObject,
  CropTwoTone,
  AutoFixHigh,
  Casino,
  ContentPaste,
  KeyboardTab,
} from "@mui/icons-material";
import { TiptapCollabProvider } from "@hocuspocus/provider";
import { Collaboration } from "@tiptap/extension-collaboration";
import * as Y from "yjs";

import { TestEditorPropertiesType } from "../../../atoms/test-editor-atom";
import {
  calculateTotalWeight,
  callGetAbstractPromptAPI,
  getPromptStepsFromTipTapNode,
  getPromptStepsText,
  trackSentryException,
} from "../../../utils/helpers";
import CollabHistory, {
  watchPreviewContent,
} from "@tiptap-pro/extension-collaboration-history";
import { DateTime } from "luxon";
import { CollabHistoryVersion } from "@tiptap-pro/extension-collaboration-history/dist/tiptap-pro/packages/extension-collaboration-history/src/types";
import { EditorState } from "prosemirror-state";
import {
  PromptStep,
  RunExecutionData,
  TemplateWithLabel,
} from "../../../utils/types";
import { CollaborationCursor } from "@tiptap/extension-collaboration-cursor";
import { createCustomCommand } from "./command-suggestions/commands";
import SuggestionsBuilder from "./command-suggestions/suggestions-builder";
import TouchApp from "@mui/icons-material/TouchApp";
import UniqueID from "@tiptap-pro/extension-unique-id";
import { atom, useSetAtom } from "jotai";
import { useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { UseMutationResult } from "@tanstack/react-query/src/types";

const darkModeColors = [
  "#B94E4E",
  "#B97D4E",
  "#A4A35A",
  "#4287A8",
  "#4E9177",
  "#6D8E47",
];
const randomDarkModeColor = () =>
  darkModeColors[Math.floor(Math.random() * darkModeColors.length)];

const TipTapEditor = ({
  testEditorProperties,
  setTestCommandsUpdated,
  testCommandsUpdated,
  stopExecution,
  running,
  setTestEditorProperties,
  provider,
  document,
  abstractPromptGeneratorEnabled,
  userEmail,
  acceptedDynamicValues,
  templates,
  suggestionsEnabled,
  isNewTest,
  auxDisplayPasteStep = false,
  setAuxDisplayPasteStep,
  selectedTestDependencyId,
  executeMutation,
  executeWithDependenciesMutation,
}: {
  testEditorProperties: TestEditorPropertiesType;
  setTestCommandsUpdated: Dispatch<SetStateAction<boolean>>;
  testCommandsUpdated: boolean;
  executeMutation: UseMutationResult<void, Error, void | PromptStep[], unknown>;
  executeWithDependenciesMutation: UseMutationResult<
    RunExecutionData[],
    Error,
    void | PromptStep,
    unknown
  >;
  running: boolean;
  stopExecution: () => void;
  setTestEditorProperties: Dispatch<SetStateAction<TestEditorPropertiesType>>;
  session: any;
  provider: TiptapCollabProvider;
  document: Y.Doc;
  abstractPromptGeneratorEnabled: boolean;
  userEmail: string;
  acceptedDynamicValues: string[];
  templates: TemplateWithLabel[];
  suggestionsEnabled: boolean;
  isNewTest: boolean;
  auxDisplayPasteStep: boolean;
  setAuxDisplayPasteStep: Dispatch<SetStateAction<boolean>>;
  selectedTestDependencyId?: string;
}) => {
  const [versions, setVersions] = React.useState<CollabHistoryVersion[]>([]);
  const [versioningModalOpen, setVersioningModalOpen] = React.useState(false);
  const [dynamicContentWarning, setDynamicContentWarning] = React.useState("");
  const setMainTipTapEditorAtom = useSetAtom(mainTipTapEditorAtom);
  const location = useLocation();
  const [displayPasteStep, setDisplayPasteStep] = useState(false);

  const templatesRef = useRef(templates);

  useEffect(() => {
    templatesRef.current = templates;
  }, [templates]);

  const editor = useEditor({
    onCreate: ({ editor }) => {
      setMainTipTapEditorAtom(editor);
      const urlSearchParams = new URLSearchParams(location.search);

      const matchParam = urlSearchParams.get("match");
      if (!!matchParam) {
        const decodedMatch = decodeURIComponent(matchParam);
        setTimeout(() => {
          const node = editor!
            .$nodes("paragraph")
            ?.find((nodePos) => nodePos.textContent.includes(decodedMatch));
          if (node != null) {
            const nodeTextContent = node.textContent;
            editor!.commands.setTextSelection({
              from:
                node.from +
                nodeTextContent
                  .toLowerCase()
                  .indexOf(decodedMatch.toLowerCase()),
              to:
                node.from +
                nodeTextContent
                  .toLowerCase()
                  .indexOf(decodedMatch.toLowerCase()) +
                decodedMatch.length,
            });
            editor.commands.focus();
          }
        }, 700);
      } else {
        const selectedNodeParam = urlSearchParams.get("selectedNode");

        if (selectedNodeParam != null) {
          setTimeout(() => {
            const node = editor!
              .$nodes("paragraph")
              ?.find((nodePos) => nodePos.attributes.id === selectedNodeParam);
            if (node != null) {
              editor!.commands.setTextSelection({
                from: node.from,
                to: node.to,
              });
              editor.commands.focus();
            }
            setDisplayPasteStep(true);
          }, 700);
        }
      }

      if (isNewTest) {
        editor.commands.setContent(
          testEditorProperties.originalTestCommands.toJSON()
        );
        setTestEditorProperties((prev) => ({
          ...prev,
          testCommands: testEditorProperties.originalTestCommands,
        }));
        setTestCommandsUpdated(false);
      }
    },
    onUpdate: ({ editor }) => {
      setTestEditorProperties((prev) => ({
        ...prev,
        testCommands: editor.state.doc,
      }));

      const origintalTestCommandsJSON =
        testEditorProperties.originalTestCommands.content.toJSON();
      if (origintalTestCommandsJSON != null) {
        setTestCommandsUpdated(
          JSON.stringify(origintalTestCommandsJSON) !==
            JSON.stringify(editor.state.doc.content.toJSON())
        );
      }

      const plainText = editor.state.doc.textContent;
      const tentativeTemplates = [...(plainText.match(/{{(.*?)}}/g) ?? [])].map(
        (match) => match.replace(/{{|}}/g, "")
      );
      const dynamicVariablesMissing = [
        ...new Set(
          tentativeTemplates.filter(
            (key) =>
              !acceptedDynamicValues.includes(key.trim()) &&
              !key.match(/randomNumber\((-?\d+)\)/)
          )
        ),
      ];
      if (dynamicVariablesMissing.length > 0) {
        const justOne = dynamicVariablesMissing.length === 1;
        if (justOne) {
          setDynamicContentWarning(
            `Variable ${dynamicVariablesMissing.join(", ")} is not defined!`
          );
        } else {
          setDynamicContentWarning(
            `Variables ${dynamicVariablesMissing.join(", ")} are not defined!`
          );
        }
      } else {
        setDynamicContentWarning("");
      }
    },

    editorProps: {
      attributes: {
        class:
          "flex-1 max-w-none w-0 prose prose-invert focus:outline-none h-[360px] overflow-auto px-3 py-1 prose-p:m-0",
      },
    },
    extensions: [
      StarterKit.configure({
        history: false, // important because history will now be handled by Y.js
      }),
      Collaboration.configure({
        document: document,
      }),
      UniqueID.configure({
        types: ["paragraph"],
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          name: userEmail,
          color: randomDarkModeColor(),
        },
      }),
      CollabHistory.configure({
        provider: provider,
        onUpdate: (data) => {
          if (data.versioningEnabled) {
            editor?.commands.toggleVersioning();
          }
          setVersions(data.versions);
        },
      }),
      ...(suggestionsEnabled
        ? [
            createCustomCommand({ name: "customCommand" }).configure({
              suggestion: SuggestionsBuilder({
                // array with all lowercase and uppercase letters
                chars: [...Array(26)].flatMap((_, i) => [
                  String.fromCharCode(i + 97),
                  String.fromCharCode(i + 65),
                ]),
                breakRegexWords: ["mpl"],
                items: (props) => {
                  const query = props.query;
                  return [
                    {
                      title: "Tap on",
                      autocompleteTemplate: "Tap on <element>",
                      alternatives: ["tap", "click"],
                      icon: <TouchApp />,
                    },
                    {
                      title: "Type text",
                      autocompleteTemplate: "Type <text>",
                      alternatives: ["type", "write"],
                      icon: <Abc />,
                    },
                    {
                      title: "Scroll",
                      autocompleteTemplate: "Scroll <direction>",
                      alternatives: ["scroll", "slide", "swipe"],
                      icon: <SwipeUp />,
                    },
                    {
                      title: "Check",
                      autocompleteTemplate:
                        "Check if you see <text/element>, if so continue with next step",
                      alternatives: ["check", "verify", "assert"],
                      icon: <Check />,
                    },
                    {
                      title: "Open deeplink",
                      autocompleteTemplate: "Open deeplink: <url>",
                      alternatives: ["open", "deeplink"],
                      icon: <Link />,
                    },
                    {
                      title: "Wait",
                      autocompleteTemplate: "Wait <number> seconds",
                      alternatives: ["wait", "sleep"],
                      icon: <HourglassBottom />,
                    },
                  ].sort((a, b) => {
                    let weightA = calculateTotalWeight(a.alternatives, query);
                    let weightB = calculateTotalWeight(b.alternatives, query);

                    // Sort in descending order of weights
                    return weightB - weightA;
                  });
                },
              }),
            }),
            createCustomCommand({ name: "envVariablesCommand" }).configure({
              suggestion: SuggestionsBuilder({
                chars: ["{{"],
                startOfLine: false,
                oneCharTrigger: false,
                allowedPrefixes: null,
                items: (props) => {
                  const query = props.query.replace("{{", "");
                  return [
                    {
                      title: "Date",
                      autocompleteTemplate: "{{currentDate}}",
                      alternatives: ["date", "day"],
                      icon: <CalendarToday />,
                    },
                    {
                      title: "Date formatted",
                      autocompleteTemplate: "{{currentDateFormatted}}",
                      alternatives: ["date", "day"],
                      icon: <CalendarToday />,
                    },
                    {
                      title: "Timestamp",
                      autocompleteTemplate: "{{timestamp}}",
                      alternatives: ["timestamp"],
                      icon: <Abc />,
                    },
                    {
                      title: "Random number with x-digits",
                      autocompleteTemplate: "{{randomNumber(<x>)}}",
                      alternatives: ["random", "number", "randomNumber"],
                      icon: <Casino />,
                    },
                    ...acceptedDynamicValues
                      .filter(
                        (value) =>
                          ![
                            "timestamp",
                            "currentDate",
                            "currentDateFormatted",
                          ].includes(value)
                      )
                      .map((envVar) => ({
                        title: `Env var <${envVar}>`,
                        autocompleteTemplate: `{{${envVar}}}`,
                        alternatives: [envVar],
                        icon: <DataObject />,
                      })),
                  ].sort((a, b) => {
                    let weightA = calculateTotalWeight(a.alternatives, query);
                    let weightB = calculateTotalWeight(b.alternatives, query);

                    // Sort in descending order of weights
                    return weightB - weightA;
                  });
                },
              }),
            }),
            createCustomCommand({ name: "templateCommand" }).configure({
              suggestion: SuggestionsBuilder({
                chars: ["tmpl"],
                startOfLine: false,
                oneCharTrigger: false,
                items: (props) => {
                  const query = props.query;
                  return [
                    ...templatesRef.current.map((template) => ({
                      title: `${template.label}`,
                      autocompleteTemplate: template.label,
                      alternatives: [template.label],
                      icon: <CropTwoTone />,
                      imageUrl: template.imageUrl,
                    })),
                  ].sort((a, b) => {
                    let weightA = calculateTotalWeight(a.alternatives, query);
                    let weightB = calculateTotalWeight(b.alternatives, query);

                    // Sort in descending order of weights
                    return weightB - weightA;
                  });
                },
              }),
            }),
          ]
        : []),
    ],
  });
  useEffect(() => {
    const selection = editor?.state.selection;
    if (auxDisplayPasteStep && selection?.empty != null && !selection.empty) {
      setDisplayPasteStep(true);
    }
  }, [auxDisplayPasteStep, editor?.state]);

  useEffect(() => {
    if (displayPasteStep) {
      const selection = editor?.state.selection;
      if (selection?.empty) {
        setDisplayPasteStep(false);
        setAuxDisplayPasteStep(false);
      }
    }
  }, [editor?.state, displayPasteStep, auxDisplayPasteStep]);

  useEffect(() => {
    if (editor != null) {
      const origintalTestCommandsJSON =
        testEditorProperties.originalTestCommands.content.toJSON();
      if (origintalTestCommandsJSON != null) {
        setTestCommandsUpdated(
          JSON.stringify(origintalTestCommandsJSON) !==
            JSON.stringify(editor.state.doc.content.toJSON())
        );
      }
    }
  }, [testEditorProperties.originalTestCommands, editor]);

  const resetEditorContent = useCallback(() => {
    if (editor != null) {
      // Capture the current selection
      const currentSelection = editor.state.selection;

      // Reset the content
      editor.commands.setContent(editor.getJSON());

      // Create a new editor state while preserving the old selection
      const newEditorState = EditorState.create({
        doc: editor.state.doc,
        plugins: editor.state.plugins,
        selection: currentSelection,
      });

      // Update the editor state
      editor.view.updateState(newEditorState);
    }
  }, [editor]);

  const handleClosePasteStepChip = useCallback(() => {
    setDisplayPasteStep(false);
  }, []);

  const handlePasteStep = useCallback(async () => {
    const urlSearchParams = new URLSearchParams(location.search);
    const selectedNodeParam = urlSearchParams.get("selectedNode");
    const pasteContent = await navigator.clipboard.readText();

    if (selectedNodeParam != null) {
      const node = editor!
        .$nodes("paragraph")
        ?.find((nodePos) => nodePos.attributes.id === selectedNodeParam);
      const pasteContent = await navigator.clipboard.readText();
      if (node != null) {
        editor!
          .chain()
          .focus()
          .insertContentAt({ from: node.from, to: node.to }, pasteContent)
          .run();
      }
    } else if (editor?.state.selection != null && auxDisplayPasteStep) {
      editor!
        .chain()
        .focus()
        .insertContentAt(
          {
            from: editor.state.selection.from!,
            to: editor.state.selection.to!,
          },
          pasteContent
        )
        .run();
    }
  }, [editor, location, auxDisplayPasteStep]);

  const handleVersioningClose = useCallback(() => {
    setVersioningModalOpen(false);
  }, []);

  const handleRevert = useCallback(
    (version, versionData) => {
      const versionTitle = versionData
        ? versionData.name || versionData.date.toString()
        : version;

      editor!.commands.revertToVersion(version, versionTitle, versionTitle);

      resetEditorContent();
    },
    [editor, provider, resetEditorContent]
  );

  const openVersioningModal = useCallback(
    () => setVersioningModalOpen(true),
    []
  );

  const handleDismiss = useCallback(() => {
    editor!.commands.saveVersion(userEmail);
    editor?.commands.setContent(
      testEditorProperties.originalTestCommands.toJSON()
    );
    editor!.commands.saveVersion(userEmail);
    resetEditorContent();
  }, [editor, resetEditorContent, userEmail, testEditorProperties]);

  return (
    <>
      <VersioningDialog
        versions={versions}
        provider={provider}
        isOpen={versioningModalOpen}
        handleClose={handleVersioningClose}
        onRevert={handleRevert}
      />
      <EditorToolBar
        stopExecution={stopExecution}
        running={running}
        selectedTestDependencyId={selectedTestDependencyId}
        editor={editor}
        openVersioningModal={openVersioningModal}
        versions={versions}
        abstractPromptGeneratorEnabled={abstractPromptGeneratorEnabled}
        executeMutation={executeMutation}
        executeWithDependenciesMutation={executeWithDependenciesMutation}
      />
      {testCommandsUpdated && (
        <Alert
          severity="warning"
          className={"mt-2.5"}
          action={
            <Button color="inherit" size="small" onClick={handleDismiss}>
              Restore Saved Version
            </Button>
          }
        >
          This is an unsaved prompt
        </Alert>
      )}
      <div>
        <BubbleMenu
          editor={editor}
          tippyOptions={{ duration: 100, placement: "top-start" }}
        >
          {displayPasteStep && (
            <Chip
              icon={<ContentPaste />}
              label="Replace with copied prompt"
              variant="filled"
              sx={{
                backgroundColor: "#616161",
                "&:hover": {
                  backgroundColor: "#616161",
                },
              }}
              onClick={handlePasteStep}
              onDelete={handleClosePasteStepChip}
            />
          )}
        </BubbleMenu>
        <EditorContent className={"flex"} editor={editor} />
      </div>
      {dynamicContentWarning.length > 0 && (
        <Alert severity="error" className={"mt-2.5"}>
          {dynamicContentWarning}
        </Alert>
      )}
    </>
  );
};
const VersioningDialog = ({
  versions,
  isOpen,
  handleClose,
  onRevert,
  provider,
}: {
  versions: CollabHistoryVersion[];
  isOpen: boolean;
  handleClose: () => void;
  onRevert: (version: number, versionData: CollabHistoryVersion) => void;
  provider: TiptapCollabProvider;
}) => {
  const [currentVersionId, setCurrentVersionId] = useState(null);
  const isCurrentVersion =
    versions && versions.length > 0
      ? currentVersionId === versions.at(-1).version
      : false;

  const editor = useEditor({
    editable: false,
    content: "",
    extensions: [StarterKit],
    editorProps: {
      attributes: {
        class:
          "flex-1 max-w-none w-0 prose prose-invert focus:outline-none overflow-auto p-3 prose-p:m-0",
      },
    },
  });

  const closeHandler = useCallback(() => {
    handleClose();
    setCurrentVersionId(null);
    editor!.commands.clearContent();
  }, [handleClose, editor]);

  const reversedVersions = useMemo(
    () => versions.slice().reverse(),
    [versions]
  );

  const handleVersionChange = useCallback(
    (newVersion) => {
      setCurrentVersionId(newVersion);

      provider.sendStateless(
        JSON.stringify({
          action: "version.preview",
          version: newVersion,
        })
      );
    },
    [provider]
  );

  const versionData = useMemo(() => {
    if (!versions.length) {
      return null;
    }

    return versions.find((v) => v.version === currentVersionId);
  }, [currentVersionId, editor, provider]);

  useEffect(() => {
    if (isOpen && currentVersionId === null && versions.length > 0) {
      const initialVersion = versions.at(-1).version;

      setCurrentVersionId(initialVersion);

      provider.sendStateless(
        JSON.stringify({
          action: "version.preview",
          version: initialVersion,
        })
      );
    }
  }, [currentVersionId, versions, isOpen]);

  useEffect(() => {
    if (isOpen) {
      const unbindContentWatcher = watchPreviewContent(provider, (content) => {
        if (editor) {
          editor.commands.setContent(content);
        }
      });

      return () => {
        unbindContentWatcher();
      };
    }
  }, [isOpen, provider, editor]);

  const handleRevert = useCallback(() => {
    const accepted = confirm("Are you sure you want to restore this version?"); // eslint-disable-line no-restricted-globals

    if (accepted) {
      onRevert(currentVersionId!, versionData!);
      closeHandler();
    }
  }, [onRevert, currentVersionId, closeHandler, versionData]);

  const getVersionName = useCallback((version: CollabHistoryVersion) => {
    if (version.name) {
      return version.name;
    }

    if (version.version === 0) {
      return "Initial version";
    }

    return `Version ${version.version}`;
  }, []);
  return (
    <Dialog fullWidth maxWidth={"lg"} open={isOpen} onClose={closeHandler}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Available versions</Box>
          <Box>
            <IconButton onClick={closeHandler}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={"m-0 p-0"} sx={{ padding: 0 }}>
        <div className={"flex w-full divide-x divide-[#ffffff1f] h-96"}>
          <div className={"flex-[2_2_0%] h-full overflow-auto"}>
            <EditorContent className={"flex"} editor={editor} />
          </div>
          <div
            className={
              "flex flex-col flex-[1_1_0%] h-full items-center divide-y divide-[#ffffff1f] w-full"
            }
          >
            <div
              className={
                "flex flex-col flex-[5_5_0%] overflow-auto w-full items-center gap-2"
              }
            >
              {reversedVersions.map((v) => (
                <VersionItem
                  date={v.date}
                  title={getVersionName(v)}
                  onClick={() => handleVersionChange(v.version)}
                  isActive={currentVersionId === v.version}
                  key={`version_item_${v.version}`}
                />
              ))}
            </div>
            <div className={"flex justify-center flex-[1_1_0%] w-full p-4"}>
              <Button
                variant={"contained"}
                onClick={handleRevert}
                disabled={!versionData || isCurrentVersion}
              >
                Restore this version
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const VersionItem = ({ title, date, isActive, onClick }) => {
  return (
    <Button
      disabled={isActive}
      onClick={onClick}
      variant={isActive ? "contained" : "text"}
      className={"flex flex-col first:mt-1 last:mb-1"}
      sx={{
        fontSize: "10px",
      }}
    >
      <span className="version-name">{title}</span>
      {title ? (
        <span className="version-date">
          {DateTime.fromMillis(date).toFormat("yyyy-MM-dd hh:mm:ss a")}
        </span>
      ) : null}
    </Button>
  );
};

const EditorToolBar = ({
  running,
  stopExecution,
  editor,
  versions,
  openVersioningModal,
  abstractPromptGeneratorEnabled,
  selectedTestDependencyId,
  executeMutation,
  executeWithDependenciesMutation,
}: {
  running: boolean;
  stopExecution: () => void;
  editor: Editor | null;
  openVersioningModal: () => void;
  versions: CollabHistoryVersion[];
  executeMutation: UseMutationResult<void, Error, void | PromptStep[], unknown>;
  executeWithDependenciesMutation: UseMutationResult<
    RunExecutionData[],
    Error,
    void | PromptStep,
    unknown
  >;
  abstractPromptGeneratorEnabled: boolean;
  selectedTestDependencyId?: string;
}) => {
  const [isGeneratingAbstract, setIsGeneratingAbstract] = useState(false);

  const executeSelection = useCallback(() => {
    const promptSteps = getPromptStepsFromTipTapNode({
      node: editor!.state.doc,
      selection: editor!.state.selection,
    });

    executeMutation.mutate(promptSteps);
  }, [editor?.state, executeMutation]);

  const generateAbstractPrompt = useCallback(async () => {
    const promptSteps = getPromptStepsFromTipTapNode({
      node: editor!.state.doc,
      selection: editor!.state.selection,
    });

    const text = getPromptStepsText(promptSteps);

    editor!.setEditable(false);
    setIsGeneratingAbstract(true);

    try {
      const abstractPrompt = await callGetAbstractPromptAPI(text);
      console.log("Received abstract prompt: ", abstractPrompt);

      if (abstractPrompt) {
        editor!
          .chain()
          .focus()
          .deleteSelection()
          .insertContent(abstractPrompt)
          .run();
      }
    } catch (e) {
      trackSentryException(e);
      console.error("Error while generating abstract prompt: ", e);
    } finally {
      editor!.setEditable(true);
      setIsGeneratingAbstract(false);
    }
  }, [editor?.state.selection, callGetAbstractPromptAPI]);

  const executeWithDependenciesHandler = useCallback(() => {
    executeWithDependenciesMutation.mutate();
  }, [executeWithDependenciesMutation]);

  const executeWithDependenciesWithUpperLimitHandler = useCallback(async () => {
    await Notification.requestPermission();

    const promptSteps = getPromptStepsFromTipTapNode({
      node: editor!.state.doc,
      selection: editor!.state.selection,
    });

    executeWithDependenciesMutation.mutate(promptSteps.at(-1));
  }, [executeWithDependenciesMutation]);

  const executeWithoutDependencies = useCallback(() => {
    executeMutation.mutate();
  }, [executeMutation]);

  const isPreparingOrRunning =
    running ||
    executeWithDependenciesMutation.isPending ||
    executeMutation.isPending;

  return (
    <div className={"flex justify-between"}>
      <div className={"flex px-2 items-center gap-0.5"}>
        <Tooltip title={"Undo"}>
          <span>
            <IconButton
              aria-label="undo"
              size="medium"
              disabled={!editor?.can().undo()}
              onClick={() => {
                editor!.commands.undo();
              }}
            >
              <Undo />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Redo"}>
          <span>
            <IconButton
              aria-label="redo"
              size="medium"
              disabled={!editor?.can().redo()}
              onClick={() => {
                editor!.commands.redo();
              }}
            >
              <Redo />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Ordered list"}>
          <IconButton
            aria-label="format-list-number"
            size="medium"
            onClick={() => {
              editor!.chain().focus().toggleOrderedList().run();
            }}
          >
            <FormatListNumbered />
          </IconButton>
        </Tooltip>
        <Tooltip title={"Check versions"}>
          <span>
            <IconButton
              disabled={versions.length === 0}
              aria-label="versions"
              size="small"
              onClick={openVersioningModal}
            >
              <History />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={"Best Practices"}>
          <IconButton
            target="_blank"
            aria-label="best practices"
            size="medium"
            href={"https://mobileboost.gitbook.io/gpt-driver-user-guide"}
          >
            <Help />
          </IconButton>
        </Tooltip>
      </div>
      <div className={"flex gap-1.5 pr-2.5 items-stretch"}>
        {!editor?.state.selection.empty ? (
          <>
            {selectedTestDependencyId != null && !isPreparingOrRunning && (
              <Tooltip
                title={"Execute until this step, including dependencies"}
              >
                <span>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={executeWithDependenciesWithUpperLimitHandler}
                    disabled={executeWithDependenciesMutation.isPending}
                    sx={{
                      minWidth: 0,
                      height: "100%",
                      "& .MuiButton-startIcon": { margin: 0 },
                    }}
                  >
                    <KeyboardTab />
                  </Button>
                </span>
              </Tooltip>
            )}

            {!isPreparingOrRunning && abstractPromptGeneratorEnabled && (
              <LoadingButton
                variant="contained"
                startIcon={<AutoFixHigh />}
                loading={isGeneratingAbstract}
                loadingPosition={"start"}
                size="medium"
                onClick={generateAbstractPrompt}
              >
                Make abstract
              </LoadingButton>
            )}
            {!isPreparingOrRunning && (
              <Button
                variant="contained"
                size="medium"
                startIcon={<PlayArrow />}
                onClick={executeSelection}
              >
                Run selected
              </Button>
            )}
          </>
        ) : (
          <>
            {selectedTestDependencyId != null && !isPreparingOrRunning && (
              <Tooltip title="Execute prompt incl. dependencies">
                <span>
                  <Button
                    id={
                      running
                        ? "stop-with-dependencies"
                        : "play-with-dependencies"
                    }
                    onClick={executeWithDependenciesHandler}
                    variant={"contained"}
                    size="medium"
                    disabled={
                      running && !executeWithDependenciesMutation.isPending
                    }
                    sx={{ height: "100%" }}
                  >
                    <VideoLibrary />
                  </Button>
                </span>
              </Tooltip>
            )}
            {!isPreparingOrRunning && (
              <Tooltip title="Execute prompt">
                <span>
                  <Button
                    variant={"contained"}
                    size="medium"
                    onClick={executeWithoutDependencies}
                    sx={{ height: "100%" }}
                  >
                    <PlayArrow />
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        )}
        {isPreparingOrRunning && (
          <LoadingButton
            variant="contained"
            size="medium"
            startIcon={<Stop />}
            onClick={stopExecution}
            loadingPosition={"start"}
            loading={
              executeWithDependenciesMutation.isPending ||
              executeMutation.isPending
            }
          >
            {executeWithDependenciesMutation.isPending ||
            executeMutation.isPending
              ? "Preparing data"
              : "Stop Execution"}
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

const mainTipTapEditorAtom = atom<Editor | null>(null);

export default TipTapEditor;
export { mainTipTapEditorAtom };
