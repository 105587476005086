import { Typography } from '@material-tailwind/react'
import { useRouteError } from 'react-router-dom'

export default function ErrorPage () {
  const error: any = useRouteError()
  console.error(error)

  return (
        <div id="error-page" className={'flex flex-col items-center justify-center w-full'}>
            <Typography variant="h1">Oops!</Typography>
            <Typography variant="lead">Sorry, an unexpected error has occurred.</Typography>
            <Typography className={'paragraph'}>
                <i>{error.statusText || error.message}</i>
            </Typography>
        </div>
  )
}
