import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import {
  AppetizeOSVersions,
  type TestDeviceConfiguration,
} from "../utils/types";
import { collection, getDocs, query, where } from "firebase/firestore";
import { customFirestore } from "../firebase";

const testDeviceConfigurationsAtom = atomFamily((organizationId: string) => {
  return atomWithQuery((get) => ({
    queryKey: ["getTestDeviceConfigurations", organizationId],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: async ({ queryKey: [, id] }) => {
      const testDeviceConfigurationsRef = collection(
        customFirestore,
        "custom-test-device-configurations"
      );

      const q = query(
        testDeviceConfigurationsRef,
        where("organisationId", "==", organizationId)
      );
      const querySnapshot = await getDocs(q);

      const configurations = querySnapshot.docs.map(
        (testDeviceConfiguration) => {
          const testDeviceConfigurationData = testDeviceConfiguration.data();

          return {
            id: testDeviceConfiguration.id,
            ...(testDeviceConfigurationData as TestDeviceConfiguration),
          };
        }
      ) as TestDeviceConfiguration[];

      return configurations;
    },
  }));
});

export { testDeviceConfigurationsAtom };
