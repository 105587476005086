import React, { useMemo } from "react";
import { useAtom, useSetAtom } from "jotai";
import { appetizeClientAtom, buildLogsAtomWithId, sessionAtom } from "../atoms";
import { type BuildLogs, CompanyData, SessionConfigData } from "../utils/types";
import { RESET } from "jotai/utils";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";

interface TestEmulatorProps {
  buildId: string;
  appetizePublicKey: string;
  landscape?: boolean;
  params?: any;
  company: CompanyData;
  sessionConfig: SessionConfigData;
}

function Simulator({
  buildId,
  appetizePublicKey,
  params,
  company,
  sessionConfig,
}: TestEmulatorProps) {
  const buildLogsAtom = buildLogsAtomWithId(buildId);
  const setBuildLogsData = useSetAtom(buildLogsAtom);

  const [session, setSession] = useAtom(sessionAtom);
  const setClient = useSetAtom(appetizeClientAtom);

  const settings = company.settings;

  useQuery({
    queryKey: ["session", settings],
    queryFn: async () => {
      // @ts-expect-error appetize will always exist
      const client = await window.appetize.getClient("#appetize");
      if (client !== null) {
        setClient(client);
      }

      client.on("session", (session: any) => {
        console.log("session", session);
        setBuildLogsData(RESET);

        const sessionStart = Date.now();
        setSession({ data: session, startedAt: sessionStart });

        if (settings.displayLogs) {
          session?.on("log", (event: any) => {
            const currentTimestamp = Date.now();
            const timestampInMs = currentTimestamp - sessionStart;

            setBuildLogsData((prev: BuildLogs) => ({
              ...prev,
              logs: [
                ...prev.logs,
                {
                  message: event.message,
                  relativeTimestamp: timestampInMs,
                  absoluteTimestamp: currentTimestamp,
                },
              ],
            }));
          });
        }

        if (settings.displayNetworkLogs) {
          session?.on("network", (event: any) => {
            const currentTimestamp = Date.now();
            const timestampInMs = currentTimestamp - sessionStart;

            setBuildLogsData((prev: BuildLogs) => ({
              ...prev,
              networkLogs: [
                ...prev.networkLogs,
                {
                  ...event,
                  relativeTimestamp: timestampInMs,
                  absoluteTimestamp: currentTimestamp,
                },
              ],
            }));
          });
        }

        session?.on("video", (event: any) => {
          const currentTimestamp = Date.now();
          const timestampInMs = currentTimestamp - sessionStart;

          setBuildLogsData((prev) => ({
            ...prev,
            videoFrames: [
              ...prev.videoFrames,
              {
                ...event,
                relativeTimestamp: timestampInMs,
                absoluteTimestamp: currentTimestamp,
              },
            ],
          }));
        });

        session?.on("audio", (event: any) => {
          const currentTimestamp = Date.now();
          const timestampInMs = currentTimestamp - sessionStart;

          setBuildLogsData((prev) => ({
            ...prev,
            audioFrames: [
              ...prev.audioFrames,
              {
                ...event,
                relativeTimestamp: timestampInMs,
                absoluteTimestamp: currentTimestamp,
              },
            ],
          }));
        });

        session?.on("action", (event: any) => {
          const currentTimestamp = Date.now();
          const timestampInMs = currentTimestamp - sessionStart;
          setBuildLogsData((prev: BuildLogs) => ({
            ...prev,
            interactionLogs: [
              ...prev.interactionLogs,
              {
                ...event,
                relativeTimestamp: timestampInMs,
                absoluteTimestamp: currentTimestamp,
              },
            ],
          }));
        });

        session?.on("interaction", (event: any) => {
          const currentTimestamp = Date.now();
          const timestampInMs = currentTimestamp - sessionStart;

          if (event.type === "typeText") {
            setBuildLogsData((prev: BuildLogs) => ({
              ...prev,
              interactionLogs: [
                ...prev.interactionLogs,
                {
                  ...event,
                  relativeTimestamp: timestampInMs,
                  absoluteTimestamp: currentTimestamp,
                },
              ],
            }));
          }
        });
      });

      return true;
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  useQuery({
    queryKey: ["sessionListenersQuery"],
    queryFn: async () => {
      // @ts-expect-error appetize will always exist
      const client = await window.appetize.getClient("#appetize");

      return true;
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  const queryString = useMemo(() => {
    const currentConfig = {
      device: sessionConfig.device,
      language: sessionConfig.language.split("_")[0],
      locale: sessionConfig.language,
      iosKeyboard: sessionConfig.language,
      record: true,
      audio: true,
      codec: "jpeg",
      iosAutocorrect: false,
      disableVirtualKeyboard: settings.disableVirtualKeyboard,
      grantPermissions: settings.autoGrantPermissions,
      proxy:
        settings.proxy ??
        (settings.displayNetworkLogs ? "intercept" : undefined),
      osVersion: sessionConfig.os,
      location:
        sessionConfig.coordinates != null
          ? [
              sessionConfig.coordinates.latitude,
              sessionConfig.coordinates.longitude,
            ]
          : undefined,
      orientation: sessionConfig.orientation,
      params: JSON.stringify({
        isGptDriver: true,
        ...(params != null && params),
      }),
      ...(sessionConfig.region != "automatic" && {
        region: sessionConfig.region,
      }),
    };

    const filteredConfig = Object.keys(currentConfig).reduce((acc, key) => {
      if (currentConfig[key] !== undefined) {
        acc[key] = currentConfig[key];
      }
      return acc;
    }, {});

    const parsedQueryString = Object.keys(filteredConfig)
      .map(
        (key) =>
          encodeURIComponent(key) +
          "=" +
          encodeURIComponent(filteredConfig[key])
      )
      .join("&");

    console.log("parsedQueryString", parsedQueryString);
    return parsedQueryString;
  }, [sessionConfig, settings]);

  return (
    <iframe
      id="appetize"
      src={`https://appetize.io/embed/${appetizePublicKey}?${queryString}&scale=auto&debug=true&centered=true${
        [
          "fb1d158b4a55ac2b071a8fab07f7844cb176a7b9",
          "40a5fa196387d3306ff47cff555e361c977d10a8",
        ].includes(company.organisationId)
          ? "&timezone=America%2FLos_Angeles"
          : ""
      }`}
      style={{ height: "100%", width: "100%" }}
    />
  );
}

export default Simulator;
