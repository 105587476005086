import React, { useCallback, useState } from "react";
import { trackSentryException } from "../utils/helpers";
import styled from "@emotion/styled";
import { Link } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const DeepLinkDialog = ({ session, activeSessionRunning }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState<string>("");

  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  const openDeeplink = useCallback(
    async (link: string) => {
      try {
        await session.data.openUrl(link);
      } catch (e) {
        trackSentryException(e);
        alert(`An error occurred while opening the deeplink: ${e}`);
        console.error("An error occurred while opening the deeplink", e);
      }
    },
    [session]
  );

  return (
    <div className="relative">
      <Tooltip title={"Open Deeplink"} placement="left">
        <span>
          <ActionButton onClick={toggleDialog} disabled={!activeSessionRunning}>
            <Link />
          </ActionButton>
        </span>
      </Tooltip>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
          <div
            className="bg-black absolute top-0 left-0 w-full h-full opacity-40"
            onClick={toggleDialog}
          />
          <div className="bg-white rounded-lg z-50 relative">
            <div className="flex justify-between items-center border-b p-4">
              <h2 className="font-semibold text-lg">Enter Deeplink / URL</h2>
            </div>
            <div>
              <form>
                <input
                  size={125}
                  defaultValue={link}
                  onChange={(e) => setLink(e.target.value)}
                  className={
                    "ring-1 h-full pl-1 pr-1 ml-3 mr-3 rounded-md ring-[#4C63FF]"
                  }
                  type="text"
                />
              </form>
            </div>
            <div className="p-4 flex justify-end">
              <button
                className={
                  "mr-4 w-20 h-8 shadow rounded-full font-medium text-center text-[#4C63FF] hover:bg-sky-50 hover:shadow-lg"
                }
                onClick={toggleDialog}
              >
                Cancel
              </button>
              <button
                disabled={link.length === 0}
                className={
                  "mr-4 w-24 h-8 shadow rounded-full font-medium text-center bg-[#4C63FF] text-white hover:bg-sky-50 hover:shadow-lg disabled:bg-gray-400 disabled:hover:shadow"
                }
                onClick={() => {
                  toggleDialog();
                  openDeeplink(link);
                }}
              >
                {" "}
                Open URL
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ActionButton = styled.button`
  color: white;
  padding: 16px;
  border-radius: 9999px;
  background-color: #4c63ff;
  &:disabled {
    color: #455a64;
    cursor: not-allowed;
    background-color: #bdbdbd;
  }
  &:hover {
    filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
      drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  }
`;

export { DeepLinkDialog };
