import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { atomWithReset } from "jotai/utils";
import { useAtom, useSetAtom } from "jotai";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Add, Close, DriveFolderUpload, Inventory } from "@mui/icons-material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { collection, doc, setDoc, writeBatch } from "firebase/firestore";
import { customFirestore } from "../../firebase";
import { arrayUnion } from "@firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import { customAlertStateAtom } from "../../components/custom-alert";
import { AuthContext } from "../../auth/AuthContext";
import TextField from "@mui/material/TextField";
import { trackSentryException } from "../../utils/helpers";

const AddFolderDialog = () => {
  const [addFolderState, setAddFolderState] = useAtom(addFolderStateAtom);
  const [title, setTitle] = useState("");

  const handleClose = useCallback((event: {}, reason: string) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    setAddFolderState({ open: false, parentFolder: undefined });
    setTitle("");
  }, []);

  const { user } = useContext<any>(AuthContext);
  const setCustomAlertState = useSetAtom(customAlertStateAtom);
  const queryClient = useQueryClient();

  const addFolderMutation = useMutation({
    mutationKey: ["addFolderMutation"],
    mutationFn: async () => {
      const newFolderDoc = doc(collection(customFirestore, "folders"));

      //TODO avoid folder name duplication
      if (addFolderState.parentFolder != null) {
        const batch = writeBatch(customFirestore);
        const parentFolderDoc = doc(
          customFirestore,
          "folders",
          addFolderState.parentFolder
        );

        batch.update(parentFolderDoc, {
          subFolders: arrayUnion(newFolderDoc.id),
        });
        batch.set(newFolderDoc, {
          name: title,
          organisationId: user.companyId,
          parentFolderId: addFolderState.parentFolder,
          tests: [],
        });
        await batch.commit();
      } else {
        setDoc(newFolderDoc, {
          name: title,
          organisationId: user.companyId,
          tests: [],
        });
      }

      await queryClient.invalidateQueries({
        queryKey: ["tests-folders-query"],
      });
      setCustomAlertState({
        open: true,
        type: "success",
        title: "New folder created successfully",
      });
      setAddFolderState({ open: false, parentFolder: undefined });
      setTitle("");
    },
    onError: (error) => {
      console.log(error);
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred while creating new folder",
      });
    },
  });

  return (
    <Dialog
      maxWidth={"xs"}
      fullWidth={true}
      open={addFolderState.open}
      onClose={handleClose}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ paddingRight: "8px", paddingTop: "10px" }}
      >
        <Box display="flex" alignItems="top">
          <Box flexGrow={1} paddingRight={"6px"} paddingTop={"4px"}>
            Add new Folder
          </Box>
          <Box>
            <Tooltip title={"Close"}>
              <span>
                <IconButton
                  disabled={addFolderMutation.isPending}
                  onClick={() => handleClose({}, "closeIconButtonClose")}
                >
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={"flex flex-col gap-3.5"}>
        <TextField
          onChange={(event) => {
            setTitle(event.target.value);
          }}
          label={"Folder name"}
          value={title}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={addFolderMutation.isPending}
          onClick={() => addFolderMutation.mutate()}
          loadingPosition="start"
          startIcon={<Add />}
          variant="contained"
        >
          Add Folder
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
const addFolderStateAtom = atomWithReset<{
  parentFolder?: string;
  open: boolean;
}>({ open: false });

export default AddFolderDialog;
export { addFolderStateAtom };
