import { PlainCard } from "../../components/settings_components";
import React, { useContext } from "react";
import { Skeleton } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCompanySettings,
  saveSettingsToFirestore2,
} from "../../utils/firebase_operations";
import { AppetizeOSVersions, FirestoreSetting } from "../../utils/types";
import { AuthContext } from "../../auth/AuthContext";
import { boolean, object, string } from "yup";
import {
  kDefaultAppetizeOSVersions,
  kLanguages,
} from "../../constants/appetize-constants";
import FormikSwitch from "../../formikComponents/formik-switch";
import FormikSelect from "../../formikComponents/formik-select";
import TestDeviceConfig from "../../components/test-device-configurations";
import { customAlertStateAtom } from "../../components/custom-alert";
import { useSetAtom } from "jotai";
import LoadingButton from "@mui/lab/LoadingButton";
import axios from "axios";
import { trackSentryException } from "../../utils/helpers";
import SectionDivider from "../../components/sectionDivider";
import { companyDataAtom } from "../test-run/test-run-atoms";

const DeviceConfigs = () => {
  const { user } = useContext<any>(AuthContext);

  const configsQuery = useQuery({
    queryKey: ["configs"],
    queryFn: async () => {
      const companySettings = await getCompanySettings(user.companyId);
      let appetizeOSVersions: AppetizeOSVersions;
      try {
        const response = await axios.get(
          "https://appetize.io/available-devices"
        );
        appetizeOSVersions = response.data as AppetizeOSVersions;
      } catch (e) {
        trackSentryException(e);
        appetizeOSVersions = kDefaultAppetizeOSVersions;
      }

      return { companySettings, appetizeOSVersions };
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  return (
    <PlainCard>
      {configsQuery.isLoading && <LoadingBody />}
      {configsQuery.isError && <p>Error: {configsQuery.error.message}</p>}
      {configsQuery.isSuccess && (
        <SuccessBody
          settingsData={configsQuery.data!.companySettings!}
          appetizeOSVersions={configsQuery.data!.appetizeOSVersions}
        />
      )}
    </PlainCard>
  );
};

const SuccessBody = ({
  settingsData,
  appetizeOSVersions,
}: {
  settingsData: FirestoreSetting;
  appetizeOSVersions: AppetizeOSVersions;
}) => {
  const { user } = useContext<any>(AuthContext);
  const queryClient = useQueryClient();
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  return (
    <>
      <Formik
        initialValues={{
          disableVirtualKeyboard: settingsData.disableVirtualKeyboard ?? false,
          splitTextWhenTyping: !(settingsData.splitTextWhenTyping ?? false),
          deviceAndroid:
            settingsData.deviceAndroid != null &&
            Object.keys(appetizeOSVersions.android).includes(
              settingsData.deviceAndroid as string
            )
              ? settingsData.deviceAndroid
              : "pixel7",
          deviceIOS:
            settingsData.deviceIOS != null &&
            Object.keys(appetizeOSVersions.ios).includes(
              settingsData.deviceIOS as string
            )
              ? settingsData.deviceIOS
              : "iphone15pro",
          iOSVersion: settingsData.iOSVersion ?? "17.2",
          androidVersion: settingsData.androidVersion ?? "13.0",
          language: settingsData.language ?? "en_US",
        }}
        validationSchema={object({
          disableVirtualKeyboard: boolean().default(false),
          splitTextWhenTyping: boolean().default(false),
          deviceAndroid: string().default("pixel7"),
          deviceIOS: string().default("iphone15pro"),
          iOSVersion: string().default("17.2"),
          androidVersion: string().default("13.0"),
          language: string().default("en_US"),
        })}
        onSubmit={async (values, formikHelpers: FormikHelpers<any>) => {
          const newValues = {
            ...values,
            splitTextWhenTyping: !values.splitTextWhenTyping,
          };
          try {
            await saveSettingsToFirestore2(user.companyId, newValues);
            await queryClient.invalidateQueries({
              queryKey: ["getParsedCompanySettings", user.companyId],
            });
            await queryClient.invalidateQueries({ queryKey: ["configs"] });
            const companyData = queryClient.getQueriesData({
              queryKey: ["getCompanyData"],
            });

            const companyDataIds = companyData.map(
              (buildArray) => buildArray[0][1]
            );
            companyDataIds.forEach((companyDataId) => {
              companyDataAtom.remove(companyDataId);
            });
            queryClient.removeQueries({ queryKey: ["getCompanyData"] });
            setCustomAlertState({
              open: true,
              type: "success",
              title: "Success",
              description: "Settings saved successfully",
            });
            formikHelpers.resetForm({ values });
          } catch (error: any) {
            trackSentryException(error);
            console.error("An error occurred during settings saving", error);
            setCustomAlertState({
              open: true,
              type: "error",
              title: "Something went wrong",
              description: "An error occurred while saving settings",
            });
          }
        }}
      >
        {({ dirty, isSubmitting, values, setValues }) => (
          <Form className={"h-full"}>
            <div className={"flex justify-between"}>
              <p className="text-3xl pl-4 font-bold">Devices</p>
              <LoadingButton
                variant="outlined"
                size={"large"}
                type="submit"
                disabled={!dirty}
                loading={isSubmitting}
              >
                Save
              </LoadingButton>
            </div>
            <div className="flex flex-col gap-4 pt-3.5 relative sm:rounded-lg h-[calc(100%-50.25px)] overflow-auto">
              <div className={"flex flex-col"}>
                <SectionDivider title={"General"} />
                <div className={"flex flex-col px-4 py-2.5"}>
                  <FormikSwitch
                    type={"checkbox"}
                    label={"Hide keyboard on Android"}
                    name={"disableVirtualKeyboard"}
                    key={`disableVirtualKeyboard`}
                  />
                  <FormikSwitch
                    type={"checkbox"}
                    label={"Faster typing"}
                    name={`splitTextWhenTyping`}
                    key={`splitTextWhenTyping`}
                  />
                </div>
              </div>
              <SectionDivider title={"Editor"} />
              <div className={"flex flex-col px-4 py-2.5 gap-5 max-w-lg"}>
                <FormikSelect
                  name={"deviceAndroid"}
                  key={"deviceAndroid"}
                  label={"Android device"}
                  options={Object.keys(appetizeOSVersions.android)}
                  onChange={(value: string) => {
                    setValues((prev) => {
                      return {
                        ...prev,
                        deviceAndroid: value,
                        androidVersion:
                          appetizeOSVersions.android[value].at(-1)!,
                      };
                    });
                  }}
                />
                <FormikSelect
                  name={"androidVersion"}
                  key={"Android Version"}
                  label={"Android Version"}
                  options={appetizeOSVersions.android[values.deviceAndroid]}
                />
                <FormikSelect
                  name={"deviceIOS"}
                  key={"deviceIOS"}
                  label={"iOS device"}
                  options={Object.keys(appetizeOSVersions.ios)}
                  onChange={(value: string) => {
                    setValues((prev) => {
                      return {
                        ...prev,
                        deviceIOS: value,
                        iOSVersion: appetizeOSVersions.ios[value].at(-1)!,
                      };
                    });
                  }}
                />
                <FormikSelect
                  name={"iOSVersion"}
                  key={"iOS Version"}
                  label={"iOS Version"}
                  options={appetizeOSVersions.ios[values.deviceIOS]}
                />
                <FormikSelect
                  name={`language`}
                  label={"Language"}
                  placeholder={"language"}
                  options={kLanguages}
                  required
                  fullWidth
                  enableCapitalize={false}
                />
              </div>
              <div className={"flex flex-col"}>
                <SectionDivider title={"Cloud"} />
                <div className={"px-5"}>
                  <TestDeviceConfig appetizeOSVersions={appetizeOSVersions} />
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const LoadingBody = () => {
  return (
    <>
      <div className="grid grid-cols-2">
        <p className="text-3xl pl-4 pb-2 font-bold">Devices</p>
      </div>
      <div className="flex flex-col gap-8 pt-2 relative sm:rounded-lg overflow-auto h-[calc(100%-50.25px)]">
        <SectionDivider title={"General"} />
        <div className={"flex flex-col gap-3"}>
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
        </div>
        <SectionDivider title={"Editor"} />
        <div className={"flex flex-col gap-3"}>
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
        </div>
        <SectionDivider title={"Cloud"} />
        <div className={"flex flex-col gap-3"}>
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
          <Skeleton variant="rounded" width={210} height={40} />
        </div>
      </div>
    </>
  );
};

export default DeviceConfigs;
