import React, {
  useState,
  useRef,
  useImperativeHandle,
  ChangeEvent,
} from "react";

interface Command {
  id: number;
  task: string;
  completed: boolean;
}

interface CommandItemProps {
  command: Command;
  handleCommandToggle: (id: number) => void;
  handleCommandDelete: (id: number) => void;
  handleEditCommand: (id: number, newTask: string) => void;
  handleMoveFocus: (currentId: number, direction: number) => void;
}

const CommandItem = React.forwardRef(
  (
    {
      command,
      handleCommandToggle,
      handleCommandDelete,
      handleEditCommand,
      handleMoveFocus,
    }: CommandItemProps,
    ref
  ) => {
    const [editCommandValue, setEditCommandValue] = useState<string>(
      command.task
    );
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.focus();
          inputRef.current.setSelectionRange(0, 0);
        }
      },
    }));

    const handleEditCommandChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      setEditCommandValue(e.target.value);
    };

    const handleEditCommandSave = () => {
      handleEditCommand(command.id, editCommandValue);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        handleEditCommandSave();
        handleMoveFocus(command.id, e.key === "ArrowUp" ? -1 : 1);
      }
    };

    return (
      <div className="flex justify-between mb-4 gap-5">
        <div className="flex items-center w-full gap-5">
          <input
            type="checkbox"
            checked={command.completed}
            onChange={() => handleCommandToggle(command.id)}
          />
          <textarea
            ref={inputRef}
            value={editCommandValue}
            onChange={handleEditCommandChange}
            onKeyDown={handleKeyDown}
            onBlur={handleEditCommandSave}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline resize-none"
            rows={1}
          />
          {/*<input*/}
          {/*  ref={inputRef}*/}
          {/*  value={editCommandValue}*/}
          {/*  onChange={handleEditCommandChange}*/}
          {/*  onKeyDown={handleKeyDown}*/}
          {/*  onBlur={handleEditCommandSave}*/}
          {/*  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"*/}
          {/*/>*/}
        </div>
        <button
          onClick={() => handleCommandDelete(command.id)}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Delete
        </button>
      </div>
    );
  }
);

function CommandList() {
  const [commands, setCommands] = useState([]);
  const [newCommand, setNewCommand] = useState("");
  const commandRefs = commands.reduce((acc, command) => {
    acc[command.id] = React.createRef();
    return acc;
  }, {});

  const handleNewCommandChange = (e) => {
    setNewCommand(e.target.value);
  };

  const handleNewCommandAdd = () => {
    if (newCommand.trim()) {
      setCommands([
        ...commands,
        { id: Date.now(), task: newCommand, completed: true },
      ]);
      setNewCommand("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleNewCommandAdd();
    }
  };

  const handleCommandToggle = (id) => {
    const updatedCommands = commands.map((command) =>
      command.id === id
        ? { ...command, completed: !command.completed }
        : command
    );
    setCommands(updatedCommands);
  };

  const handleCommandDelete = (id) => {
    setCommands(commands.filter((command) => command.id !== id));
  };

  const handleEditCommand = (id, newTask) => {
    const updatedCommands = commands.map((command) =>
      command.id === id ? { ...command, task: newTask } : command
    );
    setCommands(updatedCommands);
  };

  const handleMoveFocus = (currentId, direction) => {
    const index = commands.findIndex((command) => command.id === currentId);
    const nextIndex = Math.max(
      0,
      Math.min(commands.length - 1, index + direction)
    );
    const nextCommandRef = commandRefs[commands[nextIndex].id];
    nextCommandRef.current && nextCommandRef.current.focus();
  };

  return (
    <div className="flex flex-col items-center p-[500px]">
      <div className={"flex flex-row gap-5 items-center"}>
        <input
          value={newCommand}
          onChange={handleNewCommandChange}
          onKeyDown={handleKeyDown}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
        />
        <button
          onClick={handleNewCommandAdd}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Add
        </button>
      </div>
      <div className="flex flex-col w-full mt-6">
        {commands.map((command) => (
          <CommandItem
            ref={commandRefs[command.id]}
            key={command.id}
            command={command}
            handleCommandToggle={handleCommandToggle}
            handleCommandDelete={handleCommandDelete}
            handleEditCommand={handleEditCommand}
            handleMoveFocus={handleMoveFocus}
          />
        ))}
      </div>
    </div>
  );
}

export { CommandList };
