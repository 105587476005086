import styled from "@emotion/styled";
import { CellMeasurerCache, List } from "react-virtualized";
import { styled as materialStyled } from "@mui/material/styles";
import { Fab } from "@mui/material";

const networkLogsCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const consoleLogsCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
});

const interactionLogsCache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 560,
});

const Title = styled.p`
  font-weight: 550;
`;

const CustomList = materialStyled(List)(() => ({
  "& .beforeCurrentTime + .afterCurrentTime::before": {
    borderTop: "3px solid #90caf9",
    content: '""',
    left: "0px",
    position: "absolute",
    top: "-9.5px",
    width: "100%",
  },

  "& .afterLastItem::after": {
    borderTop: "3px solid #90caf9",
    content: '""',
    left: "0px",
    position: "absolute",
    bottom: "0px",
    width: "100%",
  },
}));

const CustomFab = materialStyled(Fab)(
  ({ jumpToTimestamp }: { jumpToTimestamp: "hidden" | "top" | "bottom" }) =>
    jumpToTimestamp != "hidden"
      ? {
          position: "absolute",
          ...(jumpToTimestamp === "top" && { top: "8px" }),
          ...(jumpToTimestamp === "bottom" && { bottom: "8px" }),
          marginInline: "auto",
          left: "50%",
          transform: " translate(0, -50%)",
        }
      : { display: "none" }
);

export {
  Title,
  networkLogsCache,
  CustomList,
  CustomFab,
  interactionLogsCache,
  consoleLogsCache,
};
