import React, { useState } from "react";
import { Cog6ToothIcon, TrashIcon } from "@heroicons/react/24/solid";

interface Props {
    className?: string
    dialogTitle: string
    innerButtonClassname: string,
    outerButtonClassname: string,
    buttonTitle: string,
    handleConfirm: () => void
    children?: React.ReactNode
    buttonChildren?: React.ReactNode
}

const DialogWindow = ({ className, dialogTitle, innerButtonClassname, outerButtonClassname, buttonTitle, handleConfirm, children, buttonChildren }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDialog = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={className}>
            <button className={outerButtonClassname}
                    onClick={toggleDialog}>
                {buttonTitle}
                {buttonChildren}
            </button>
            {isOpen && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
                    <div className="bg-black absolute top-0 left-0 w-full h-full opacity-40" onClick={toggleDialog}/>
                    <div className="bg-white rounded-lg z-50 relative">
                        <div className="flex justify-between items-center border-b p-4">
                            <h2 className="font-semibold text-black text-lg">{dialogTitle}</h2>
                        </div>
                        <div>
                            {children}
                        </div>
                        <div className="p-4 flex justify-end">
                            <button className={"mr-4 w-20 h-8 shadow rounded-full font-medium text-center text-[#4C63FF] hover:bg-sky-50 hover:text-[#4C63FF] hover:shadow-lg"} onClick={toggleDialog}>
                                Cancel
                            </button>
                            <button className={innerButtonClassname} onClick={() => {
                                toggleDialog();
                                handleConfirm();
                            }}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DialogWindow