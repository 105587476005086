import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { Close, Save } from "@mui/icons-material";

interface AsyncFunction {
  (): Promise<void>;
}

const AsyncConfirmationModal = ({
  open,
  title,
  description,
  actionTitle,
  backTitle,
  actionHandler,
  actionIcon,
  secondaryActionHandler,
  closeHandler,
  submitting,
}: {
  open: boolean;
  title: string;
  actionTitle: string;
  description: string;
  backTitle: string;
  actionHandler: AsyncFunction;
  actionIcon?: React.ReactNode;
  secondaryActionHandler?: () => void;
  closeHandler?: () => void;
  submitting: boolean;
}) => {
  const handleClose = useCallback(
    (event: {}, reason: string) => {
      if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
        return;
      closeHandler?.();
    },
    [closeHandler]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ paddingRight: "8px", paddingTop: "10px" }}
      >
        <Box display="flex" alignItems="top">
          <Box flexGrow={1} paddingRight={"6px"} paddingTop={"4px"}>
            {title}
          </Box>
          <Box>
            <Tooltip title={"Close"}>
              <span>
                <IconButton
                  disabled={submitting}
                  onClick={() => handleClose({}, "closeIconButtonClose")}
                >
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={
            secondaryActionHandler != null
              ? secondaryActionHandler
              : closeHandler
          }
          disabled={submitting}
        >
          {backTitle}
        </Button>
        <LoadingButton
          loading={submitting}
          loadingPosition="start"
          startIcon={actionIcon}
          variant="contained"
          onClick={actionHandler}
        >
          {actionTitle}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export { AsyncConfirmationModal };
