import { FieldArray, Form, Formik } from "formik";
import React from "react";
import { array, boolean, number, object, string, ValidationError } from "yup";
import { EnvVar, Test } from "../../utils/types";
import { Button, IconButton, Paper } from "@mui/material";
import { AddCircle, Help, RemoveCircle } from "@mui/icons-material";
import FormikTextInput from "../../formikComponents/formik-text-input";
import FormikCheckbox from "../../formikComponents/formik-checkbox";

const TestEditorSettings = ({
  test,
  formRef,
  setIsDirty,
  envVars,
}: {
  test?: Test;
  formRef: any;
  setIsDirty: any;
  envVars: EnvVar[];
}) => {
  return (
    <Paper sx={{ padding: "16px", width: "100%" }}>
      <Formik
        innerRef={formRef}
        initialValues={{
          inputKeys: test?.settings?.inputKeys ?? [],
          launchParams: test?.settings?.launchParams ?? [],
          preRequests: test?.settings?.preRequests ?? [],
        }}
        validationSchema={object({
          inputKeys: array(
            object({
              key: string().required("Required field"),
              defaultValue: string().notRequired(),
            }).required("Required field")
          ).default([]),
          launchParams: array(
            object({
              key: string().required("Required field"),
              value: string().required("Required field"),
            })
          ).default([]),
          preRequests: array(
            object({
              curlCommand: string().required("Required field"),
              valuesToRemember: array(
                string().required("Required field")
              ).default([]),
              delayBeforeRequest: number().required("Required field"),
            })
          ).default([]),
        }).test(
          "pre-request-templates-validator",
          "check if templates values used in curl commands are previously stored",
          ({ preRequests, inputKeys }, ctx) => {
            const validationErrors: ValidationError[] = preRequests.reduce<
              ValidationError[]
            >((prev, current, currentIndex, preRequestsArray) => {
              const tentativeTemplates = [
                ...(current.curlCommand?.match(/{{(.*?)}}/g) ?? []),
              ].map((match) => match.replace(/{{|}}/g, ""));
              const rememberedValues = preRequests
                .slice(0, currentIndex)
                .map((preRequest) => preRequest.valuesToRemember ?? [])
                .flat()
                .filter((value) => !!value)
                .map((value) => value.trim().split(".").at(-1));

              if (
                !tentativeTemplates.every(
                  (template) =>
                    rememberedValues.includes(template) ||
                    inputKeys
                      .map((inputKey) => inputKey.key)
                      .includes(template) ||
                    envVars
                      .map((envVar) => `env.${envVar.key}`)
                      .includes(template)
                )
              ) {
                return [
                  ...prev,
                  ctx.createError({
                    path: `preRequests.${currentIndex}.curlCommand`,
                    message: `Curl command contains template or test input value that is not previously stored`,
                  }),
                ];
              }
              return prev;
            }, []);

            if (validationErrors.length > 0) {
              return new ValidationError(validationErrors);
            }
            return true;
          }
        )}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          resetForm();
          setSubmitting(false);
        }}
        validateOnBlur={true}
      >
        {({ values }) => (
          <Form>
            <div className={"flex flex-col gap-4 items-start"}>
              <div className={"flex gap-1 items-center"}>
                <p className={"text-xl"}>Test inputs</p>
              </div>
              <FieldArray
                name={"inputKeys"}
                render={(arrayHelpers) => (
                  <div className={"flex flex-col gap-4"}>
                    {values.inputKeys.map((item, index) => (
                      <div key={index} className={"flex gap-1 items-start"}>
                        <FormikTextInput
                          name={`inputKeys.${index}.key`}
                          label={"Key"}
                          placeholder={"Key"}
                          type={"text"}
                          required
                          onChange={() => {
                            setIsDirty(true);
                          }}
                        />
                        <FormikTextInput
                          name={`inputKeys.${index}.defaultValue`}
                          label={"Default Value"}
                          type={"text"}
                          onChange={() => {
                            setIsDirty(true);
                          }}
                        />
                        <IconButton
                          sx={{ marginTop: "8px" }}
                          aria-label="delete"
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setIsDirty(true);
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </div>
                    ))}
                    <Button
                      variant="text"
                      sx={{ alignSelf: "flex-start" }}
                      endIcon={<AddCircle />}
                      onClick={() => {
                        arrayHelpers.push("");
                        setIsDirty(true);
                      }}
                    >
                      Add input key
                    </Button>
                  </div>
                )}
              ></FieldArray>
              <div className={"flex gap-1 items-center"}>
                <p className={"text-xl"}>Launch params</p>
                <IconButton
                  target="_blank"
                  aria-label="launch params docs"
                  size="medium"
                  href={"https://gpt-driver.readme.io/docs/launch-parameters"}
                >
                  <Help />
                </IconButton>
              </div>
              <FieldArray
                name={"launchParams"}
                render={(arrayHelpers) => (
                  <div className={"flex flex-col gap-4"}>
                    {values.launchParams.map((item, index) => (
                      <div key={index} className={"flex gap-3 items-start"}>
                        <FormikTextInput
                          name={`launchParams.${index}.key`}
                          label={"Key"}
                          placeholder={"Key"}
                          type={"text"}
                          required
                          onChange={() => {
                            setIsDirty(true);
                          }}
                        />
                        <FormikTextInput
                          name={`launchParams.${index}.value`}
                          label={"Value"}
                          placeholder={"Value"}
                          type={"text"}
                          required
                          onChange={() => {
                            setIsDirty(true);
                          }}
                        />
                        <IconButton
                          sx={{ marginTop: "8px" }}
                          aria-label="delete"
                          onClick={() => {
                            arrayHelpers.remove(index);
                            setIsDirty(true);
                          }}
                        >
                          <RemoveCircle />
                        </IconButton>
                      </div>
                    ))}
                    <Button
                      variant="text"
                      sx={{ alignSelf: "flex-start" }}
                      endIcon={<AddCircle />}
                      onClick={() => {
                        arrayHelpers.push({
                          key: "",
                          value: "",
                        });
                        setIsDirty(true);
                      }}
                    >
                      Add param
                    </Button>
                  </div>
                )}
              ></FieldArray>
              <div className={"flex gap-1 items-center"}>
                <p className={"text-xl"}>Pre-requests</p>
                <IconButton
                  target="_blank"
                  aria-label="pre requests docs"
                  size="medium"
                  href={
                    "https://mobileboost.gitbook.io/gpt-driver-user-guide/best-practices/network-calls#curl-network-requests-before-the-test-prompt-execution"
                  }
                >
                  <Help />
                </IconButton>
              </div>
              <FieldArray
                name={"preRequests"}
                render={(arrayHelpers) => {
                  return (
                    <div className={"flex flex-col gap-4 w-full items-stretch"}>
                      {values.preRequests.map((item, preRequestIndex) => (
                        <div
                          key={preRequestIndex}
                          className={"flex items-start w-full gap-1"}
                        >
                          <div className={"flex flex-1 gap-2"}>
                            <div className={"flex flex-col gap-3 w-full"}>
                              <FormikTextInput
                                name={`preRequests.${preRequestIndex}.curlCommand`}
                                label={"Curl command"}
                                placeholder={"Curl command"}
                                type={"text"}
                                sx={{ width: "100%" }}
                                required
                                onChange={() => {
                                  setIsDirty(true);
                                }}
                              />
                              <FieldArray
                                name={`preRequests.${preRequestIndex}.valuesToRemember`}
                                render={(valuesToRememberArrayHelpers) => {
                                  return (
                                    <div className={"flex flex-col gap-4"}>
                                      {item.valuesToRemember.map(
                                        (
                                          valueToRemember,
                                          valueToRememberIndex
                                        ) => (
                                          <div
                                            key={valueToRememberIndex}
                                            className={"flex gap-3"}
                                          >
                                            <FormikTextInput
                                              name={`preRequests.${preRequestIndex}.valuesToRemember.${valueToRememberIndex}`}
                                              label={"Value"}
                                              placeholder={"Value"}
                                              type={"text"}
                                              required
                                              onChange={() => {
                                                setIsDirty(true);
                                              }}
                                            />
                                            <IconButton
                                              sx={{ marginTop: "8px" }}
                                              aria-label="delete"
                                              onClick={() => {
                                                valuesToRememberArrayHelpers.remove(
                                                  valueToRememberIndex
                                                );
                                                setIsDirty(true);
                                              }}
                                            >
                                              <RemoveCircle />
                                            </IconButton>
                                          </div>
                                        )
                                      )}
                                      <Button
                                        variant="text"
                                        sx={{ alignSelf: "flex-start" }}
                                        endIcon={<AddCircle />}
                                        onClick={() => {
                                          valuesToRememberArrayHelpers.push("");
                                          setIsDirty(true);
                                        }}
                                      >
                                        Add value to remember
                                      </Button>
                                    </div>
                                  );
                                }}
                              ></FieldArray>
                            </div>
                          </div>
                          {preRequestIndex !== 0 && (
                            <FormikTextInput
                              name={`preRequests.${preRequestIndex}.delayBeforeRequest`}
                              label={"Delay in ms"}
                              placeholder={"Delay in ms"}
                              type={"text"}
                              required
                              className={"basis-48"}
                              onChange={() => {
                                setIsDirty(true);
                              }}
                            />
                          )}
                          <IconButton
                            sx={{ marginTop: "8px" }}
                            aria-label="delete"
                            onClick={() => {
                              arrayHelpers.remove(preRequestIndex);
                              setIsDirty(true);
                            }}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </div>
                      ))}
                      <Button
                        variant="text"
                        sx={{ alignSelf: "flex-start" }}
                        endIcon={<AddCircle />}
                        onClick={() => {
                          arrayHelpers.push({
                            curlCommand: "",
                            valuesToRemember: [],
                            delayBeforeRequest:
                              values.preRequests.length > 0 ? 1000 : 0,
                          });
                          setIsDirty(true);
                        }}
                      >
                        Add Pre-Request
                      </Button>
                    </div>
                  );
                }}
              ></FieldArray>
            </div>
          </Form>
        )}
      </Formik>
    </Paper>
  );
};

export default TestEditorSettings;
