import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  MenuBookOutlined,
  Science,
  Settings,
  Summarize,
  Widgets,
  Compare,
} from "@mui/icons-material";
import { AuthContext } from "../auth/AuthContext";
import { useAtomValue } from "jotai/index";
import { companyDataAtom } from "../routes/test-run/test-run-atoms";

const GptDriverLeftNavigation = () => {
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const { user } = useContext<any>(AuthContext);

  const { data, isError, isLoading } = useAtomValue(
    companyDataAtom(user.companyId)
  );

  return (
    <div className="flex flex-col items-center justify-center h-full bg-white">
      <div
        className={
          "flex-1 flex flex-col h-full justify-start border-t divide-y "
        }
      >
        <MenuItem path={"/gpt-driver/tests"} title={"Tests"}>
          <Science />
        </MenuItem>
        <MenuItem path={"/gpt-driver/reports"} title={"Reports"}>
          <Summarize />
        </MenuItem>
        <MenuItem path={"/gpt-driver/builds"} title={"Builds"}>
          <Widgets />
        </MenuItem>
        {data != null && data.settings.enableSessionsFeature && (
          <MenuItem path={"/gpt-driver/sessions"} title={"Sessions"}>
            <Compare />
          </MenuItem>
        )}
        <MenuItem path={"/gpt-driver/settings"} title={"Settings"}>
          <Settings />
        </MenuItem>
        <div className={"flex flex-col flex-1 justify-end"}>
          <IconButton
            className={"flex items-center gap-3 h-20 w-20"}
            target={"_blank"}
            href={"https://mobileboost.gitbook.io/gpt-driver-user-guide"}
            sx={{
              borderRadius: 0,
              display: "flex",
              flexDirection: "column",
              gap: "1px",
              fontSize: "12px",
              ".MuiSvgIcon-root": {
                height: "32px",
                width: "32px",
                color: "#263238",
              },
            }}
          >
            <MenuBookOutlined />
            <p>Manual</p>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

const MenuItem = ({
  path,
  children,
  title,
  ...props
}: {
  path: string;
  title?: string;
  children: ReactNode;
  props?: any;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  const isActive = useMemo(
    () => location.pathname.includes(path),
    [location.pathname, path]
  );

  return (
    <div className={"relative"}>
      <IconButton
        {...props}
        className={"flex items-center gap-3 h-20 w-20"}
        onClick={() => {
          navigateTo(path);
        }}
        sx={{
          borderRadius: 0,
          display: "flex",
          flexDirection: "column",
          gap: "1px",
          color: isActive ? "#4C63FF" : "#263238",
          fontSize: "12px",
          ".MuiSvgIcon-root": {
            height: "32px",
            width: "32px",
          },
        }}
      >
        {children}
        {title && <p>{title}</p>}
      </IconButton>
      {isActive && (
        <div className={"absolute top-0 w-2 h-full bg-[#4C63FF]"}></div>
      )}
    </div>
  );
};

export default GptDriverLeftNavigation;
