import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react";
import { AuthenticatedRoute } from "../components/authenticatedRoute";
import GptDriverLeftNavigation from "../components/gpt-driver-left-navigation";
import { CustomAlert, customAlertStateAtom } from "../components/custom-alert";
import { FirestoreError, doc, onSnapshot } from "firebase/firestore";
import { customFirestore, firebaseAuth } from "../firebase";
import { GlobalSettings } from "../utils/types";
import ForceUpdateDialog, {
  forceUpdateConditionsStateAtom,
} from "./force-update-dialog";
import { gt } from "semver";
import { useAtomValue, useSetAtom } from "jotai";
import styled from "@emotion/styled";
import TestingAppBar from "../components/testing_app_bar";
import driverLogo from "../assets/images/diver-logo.png";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "../auth/AuthContext";
import { Logout, Send } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "@tanstack/react-query";
import { sendEmailVerification, signOut, User } from "firebase/auth";
import { trackSentryException } from "../utils/helpers";

function GptDriverApp() {
  const [minPackageVersion, setMinPackageVersion] = useState<string | null>(
    null
  );
  const [error, setError] = useState<FirestoreError | null>(null);
  const [loading, setLoading] = useState(true);
  const forceUpdateConditionsState = useAtomValue(
    forceUpdateConditionsStateAtom
  );

  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    setLoading(true);

    const globalSettingsRef = doc(customFirestore, "settings", "global");
    const unsubscribe = onSnapshot(
      globalSettingsRef,
      (snapshot) => {
        const globalSettings = snapshot.data() as GlobalSettings;
        setMinPackageVersion(globalSettings.minVersion);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
        setError(error);
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  const location = useLocation();

  const openForceUpdateDialog = useMemo(() => {
    if (location.pathname.includes("tests-suites/")) {
      return false;
    }
    if (
      minPackageVersion != null &&
      !forceUpdateConditionsState.activeSession &&
      !forceUpdateConditionsState.unsavedTest
    ) {
      const currentVersion = import.meta.env.VITE_REACT_APP_VERSION;
      return gt(minPackageVersion, currentVersion!);
    }
    return false;
  }, [minPackageVersion, location, forceUpdateConditionsState]);
  const { user } = useContext<any>(AuthContext);

  if (loading) {
    return (
      <SpinnerWrapper>
        <CircularProgress />
      </SpinnerWrapper>
    );
  }

  if (error != null) {
    return (
      <div className="flex justify-center pt-24">
        <p>{error.message}</p>
      </div>
    );
  }

  if (!!user.isAnonymous || !user.uid) {
    return (
      <AnonymousAppWrapper>
        <ContentWrapper>
          <ForceUpdateDialog open={openForceUpdateDialog} />
          <AuthenticatedRoute>
            <Outlet />
            <CustomAlert />
          </AuthenticatedRoute>
        </ContentWrapper>
      </AnonymousAppWrapper>
    );
  }

  if (user.uid && !user.emailVerified) {
    return (
      <>
        <CustomAlert />
        <VerifyEmail firebaseUser={user.firebaseUser} />
      </>
    );
  }

  return (
    <AppWrapper>
      <LogoWrapper
        onClick={() => {
          navigateTo("/gpt-driver/tests");
        }}
      >
        <img src={driverLogo} className={"w-[32px]"} alt={"App logo"} />
      </LogoWrapper>
      <TopBarWrapper />
      <MenuBarWrapper />
      <ContentWrapper>
        <ForceUpdateDialog open={openForceUpdateDialog} />
        <AuthenticatedRoute>
          <Outlet />
          <CustomAlert />
        </AuthenticatedRoute>
      </ContentWrapper>
    </AppWrapper>
  );
}

const VerifyEmail = ({ firebaseUser }: { firebaseUser: User }) => {
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  useEffect(() => {
    sendEmailVerification(firebaseUser);
  }, []);

  const sendMutation = useMutation({
    mutationKey: ["verifyEmail"],
    mutationFn: () => sendEmailVerification(firebaseUser),
    onSuccess: () => {
      setCustomAlertState({
        open: true,
        type: "success",
        title: "Verification email sent, please verify your inbox",
      });
    },
    onError: (error) => {
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred sending verification email",
      });
    },
  });

  const logoutMutation = useMutation({
    mutationKey: ["logoutFromVerifyEmail"],
    mutationFn: () => signOut(firebaseAuth),
    onError: (error) => {
      trackSentryException(error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Something went wrong",
        description: "An error occurred trying to logout",
      });
    },
  });

  return (
    <div className="flex flex-col items-center pt-24 gap-4">
      <p>{firebaseUser.email}</p>
      <p>
        You need to verify your email to use GPT Driver, please check your email
        and verify your account.
      </p>
      <p>Haven't received the email yet? Try again</p>
      <LoadingButton
        onClick={() => sendMutation.mutate()}
        endIcon={<Send />}
        loading={sendMutation.isPending}
        loadingPosition="end"
        variant="contained"
      >
        Send
      </LoadingButton>
      <LoadingButton
        color={"error"}
        onClick={() => logoutMutation.mutate()}
        endIcon={<Logout />}
        loading={logoutMutation.isPending}
        loadingPosition="end"
        variant="contained"
      >
        Logout
      </LoadingButton>
    </div>
  );
};

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const ContentWrapper = styled.div`
  grid-area: content;
  position: relative;
`;

const LogoWrapper = styled.div`
  grid-area: logo;
  height: 60px;
  background-color: #4c63ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuBarWrapper = styled(GptDriverLeftNavigation)(() => ({
  gridArea: "menuBar",
}));

const TopBarWrapper = styled(TestingAppBar)(() => ({
  gridArea: "topBar",
}));

const AppWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "logo topBar"
    "menuBar content";
  grid-template-columns: 80px 1fr;
  grid-template-rows: 60px minmax(0, 1fr);
  background-color: #d9d9d9;
  height: 100%;
`;

const AnonymousAppWrapper = styled.div`
  display: grid;
  grid-template-areas: "content";
  background-color: #d9d9d9;
  height: 100%;
  grid-template-rows: minmax(0, 1fr);
`;

export default GptDriverApp;
