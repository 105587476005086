import * as React from "react";
import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import renderSpinner from "./spinner";
import { toast, ToastOptions } from "react-toastify";
import { RenderSpinner } from "./settings_components";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import { trackSentryException } from "../utils/helpers";

const ERROR_TOAST: ToastOptions = {
  position: "top-center",
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export default function FormDialog(addMembers: any) {
  const [open, setOpen] = React.useState(false);
  const [handlingEmails, setHandlingEmails] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmails = (emailsString: string) => {
    const verifyEmail = (email: string) => {
      return /^\S+@\S+\.\S+$/.test(email);
    };

    const mailsSeparated: string[] = emailsString.replace(/\s/g, "").split(",");
    const validEmails: string[] = mailsSeparated.filter((email) =>
      verifyEmail(email)
    );
    const incorrectEmails: string[] = mailsSeparated.filter(
      (email) => !verifyEmail(email)
    );

    if (incorrectEmails.length > 0) {
      if (incorrectEmails[0] === "") {
        toast.info(`Please enter at least one email`, ERROR_TOAST);
      } else {
        toast.error(`Invalid emails passed: ${incorrectEmails}`, ERROR_TOAST);
      }
    }

    return {
      allValid: incorrectEmails.length === 0,
      validEmails: validEmails,
    };
  };

  const handleConfirm = async (event: any) => {
    // Prevent the browser from reloading the page
    event.preventDefault();

    // Read the form data
    const formData = new FormData(event.target);
    // @ts-ignore
    const emailsString: string = Object.fromEntries(formData.entries()).emails;

    const { allValid, validEmails } = validateEmails(emailsString);
    if (allValid) {
      setHandlingEmails(true);
      try {
        await addMembers.mutate(validEmails);
        setHandlingEmails(false);
        setOpen(false);
      } catch (error: any) {
        trackSentryException(error);
        console.error(error);
        setHandlingEmails(false);
      }
    }
  };

  return (
    <div className="pr-4">
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        size={"large"}
        startIcon={<Add></Add>}
      >
        Invite users
      </Button>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <form method="post" onSubmit={handleConfirm}>
          <DialogTitle>Invite users</DialogTitle>
          <DialogContent>
            <div>
              <label
                htmlFor="about"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Enter a comma-separated list of users email addresses.
              </label>
              <div className="mt-2">
                <textarea
                  id="emails"
                  name="emails"
                  rows={3}
                  className="mt-1 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-[#4C63FF] placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-100 sm:py-1.5 sm:text-sm sm:leading-6"
                  placeholder="  user1@example.com, user2@example.com, ..."
                ></textarea>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="p-4 gap-x-2">
              <button
                type="button"
                className="h-10 w-24 rounded-full hover:bg-sky-50 font-normal text-center text-[#4C63FF]"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="shadow h-10 w-24 text-center font-semibold bg-sky-50 rounded-full hover:shadow-lg hover:bg-[#4C63FF] text-lg transition text-[#4C63FF] hover:text-blue-50"
              >
                {handlingEmails ? <RenderSpinner /> : "Confirm"}
              </button>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
