import React from "react";
import { VideoJS } from "./videojs";
import { Typography } from "@mui/material";

const RecordingVideoPlayer = ({
  videoUrl,
}: // setPlaybackTimestamp,
{
  videoUrl?: string;
}) => {
  const videoJsOptions = {
    controls: true,
    responsive: true,
    fill: true,
    sources: [
      {
        src: videoUrl,
        type: "video/mp4",
      },
    ],
  };

  return (
    <>
      {videoUrl == null ? (
        <Typography>Video Recording will be available shortly</Typography>
      ) : (
        <VideoJS
          options={videoJsOptions}
          // setPlaybackTimestamp={setPlaybackTimestamp}
        />
      )}
    </>
  );
};

export default RecordingVideoPlayer;
