import React, { useContext, useState } from "react";
import InvitesPanel from "../components/invites";
import TemplatesPanel from "../components/templates";
import { AuthContext } from "../auth/AuthContext";
import CompanySettingsPanel from "../components/company_settings";
import { Button } from "@mui/material";
import { EnvVarsPanel } from "../components/envVars";
import DeviceConfigs from "./settings/device-configs";
import { useLocation } from "react-router-dom";
import styled from "@emotion/styled";

const GlobalSettings = () => {
  let { state } = useLocation();
  const [displayValue, setDisplayValue] = useState(
    state?.settingsSection ?? "settings"
  );
  const { user } = useContext<any>(AuthContext);

  const displayCorrectPanel = (displayValue: string) => {
    switch (displayValue) {
      case "settings":
        return <CompanySettingsPanel companyId={user.companyId} />;
      case "templates":
        return <TemplatesPanel />;
      case "members":
        return <InvitesPanel companyId={user.companyId} />;
      case "envVars":
        return <EnvVarsPanel companyId={user.companyId} />;
      case "devices":
        return <DeviceConfigs />;
      default:
        return <CompanySettingsPanel companyId={user.companyId} />;
    }
  };

  return (
    <ContentWrapper>
      <TabsWrapper>
        <NavigationButton
          onClick={() => setDisplayValue("settings")}
          title={"Settings"}
          active={displayValue !== "settings"}
        />
        <NavigationButton
          onClick={() => setDisplayValue("members")}
          title={"Members"}
          active={displayValue !== "members"}
        />
        <NavigationButton
          onClick={() => setDisplayValue("devices")}
          title={"Devices"}
          active={displayValue !== "devices"}
        />
        <NavigationButton
          onClick={() => setDisplayValue("envVars")}
          title={"Env Variables"}
          active={displayValue !== "envVars"}
        />
        <NavigationButton
          onClick={() => setDisplayValue("templates")}
          title={"Templates"}
          active={displayValue !== "templates"}
        />
      </TabsWrapper>
      <PanelWrapper>{displayCorrectPanel(displayValue)}</PanelWrapper>
    </ContentWrapper>
  );
};

const NavigationButton = ({
  onClick,
  title,
  active,
}: {
  onClick: () => void;
  title: string;
  active: boolean;
}) => {
  return (
    <Button
      variant="contained"
      size={"large"}
      disabled={!active}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas:
    "tabs"
    "settings";
  row-gap: 20px;
  padding: 16px;
  background-color: #e8e8e8;
  grid-template-rows: auto minmax(0, 1fr);
  margin: 16px;
  border-radius: 8px;
  height: calc(100% - 32px);
`;

const TabsWrapper = styled.div`
  grid-area: tabs;
  display: flex;
  gap: 8px;
`;

const PanelWrapper = styled.div`
  grid-area: settings;
`;

export default GlobalSettings;
