import { useLoaderData, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import React, { useContext, useEffect, useMemo } from "react";
import {
  TestSuiteRunRecordingData,
  TestSuiteRecordingApp,
} from "../../utils/types";
import RecordingVideoPlayer from "../../components/recording-video-player";
import RecordingInformation from "./recording_information";
import { LogDetailDialog } from "../../components/test-editor-gpt/execution-logs/logDetailDialog";
import { darkTheme } from "../../themes";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import styled from "@emotion/styled";
import {
  CircularProgress,
  Paper,
  styled as styledMaterial,
  Typography,
} from "@mui/material";
import { AuthContext } from "../../auth/AuthContext";
import { companyDataAtom } from "../test-run/test-run-atoms";

const TestRecordingPage = () => {
  const loaderData = useLoaderData() as TestSuiteRecordingApp;
  const { data, isLoading, isError, error } = useAtomValue(
    loaderData.testRunDataAtom
  );
  const { error: testSuiteError } = useAtomValue(loaderData.testSuiteDataAtom);
  const { user } = useContext<any>(AuthContext);

  const location = useLocation();

  useEffect(() => {
    if (testSuiteError != null) {
      if (testSuiteError.code === "permission-denied") {
        console.log("location", location);
        window.location.replace("/gpt-driver/tests");
      }
    }
  }, [testSuiteError]);

  return (
    <ThemeProvider theme={darkTheme}>
      <ContentWrapper>
        {!user.isAnonymous && !isError && !isLoading && data != null && (
          <LogDetailDialogWrapper runRecordingData={data} />
        )}
        <VideoSection>
          {isLoading && (
            <SpinnerWrapper>
              <CircularProgress />
            </SpinnerWrapper>
          )}
          {isError && (
            <Typography variant={"h6"} color={"error"}>
              Error: {error.message}
            </Typography>
          )}
          {!isError && !isLoading && data != null && (
            <RecordingVideoPlayer
              videoUrl={data.runs[data.runs.length - 1]!.runData!.videoUrl}
            />
          )}
        </VideoSection>
        <InformationSection elevation={0} square>
          {data != null && <RecordingInformation runRecordingData={data} />}
        </InformationSection>
      </ContentWrapper>
    </ThemeProvider>
  );
};

const ContentWrapper = styled.div`
  display: grid;
  grid-template-areas: "video information";
  grid-template-columns: 30% 70%;
  height: 100%;
`;

const VideoSection = styled.div`
  grid-area: video;
`;

const InformationSection = styledMaterial(Paper)({
  gridArea: "information",
  overflow: "auto",
});

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const LogDetailDialogWrapper = ({
  runRecordingData,
}: {
  runRecordingData: TestSuiteRunRecordingData;
}) => {
  const loaderData = useLoaderData() as TestSuiteRecordingApp;
  const { data: suiteData } = useAtomValue(loaderData.testSuiteDataAtom);
  const {
    data: companyData,
    isLoading,
    isError,
    error,
  } = useAtomValue(companyDataAtom(suiteData!.organisationId));

  const parsedEnvVars = useMemo(
    () =>
      companyData?.settings?.envVars?.map((envVar) => `env.${envVar.key}`) ??
      [],
    [companyData?.settings?.envVars]
  );

  const acceptedDynamicValues = useMemo(
    () => [
      "timestamp",
      "currentDate",
      "currentDateFormatted",
      ...parsedEnvVars,
      ...(runRecordingData.runs
        ?.at(-1)
        ?.settings?.inputKeys?.map?.((inputKey) => inputKey.key) ?? []),
    ],
    [parsedEnvVars, runRecordingData.runs]
  );

  return (
    <>
      {!isLoading && !isError && companyData != null && (
        <ThemeProvider theme={darkTheme}>
          <LogDetailDialog
            acceptedDynamicValues={acceptedDynamicValues}
            companyData={companyData}
            platform={suiteData!.platform!}
            buildId={suiteData!.uploadId!}
            parentTestId={runRecordingData.runs.at(-1)!.testId!}
          />
        </ThemeProvider>
      )}
    </>
  );
};

export { TestRecordingPage };
