import React, { useCallback, useContext } from "react";
import { useAtomValue } from "jotai";
import { AuthContext } from "../../auth/AuthContext";
import { useAtom } from "jotai";
import { atomWithReset, RESET } from "jotai/utils";
import getBuildsAtom from "../../atoms/get-builds-atom";
import { DateTime } from "luxon";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { delay } from "../../utils/helpers";

const SelectBuildDialog = () => {
  const { user } = useContext<any>(AuthContext);
  const buildsQuery = useAtomValue(
    getBuildsAtom({ organizationId: user.companyId, limit: 20 })
  );
  const [buildDialogState, setBuildDialogState] = useAtom(buildDialogStateAtom);

  const handleChange = (event) => {
    setBuildDialogState((prev) => ({
      ...prev,
      selectedBuildId: event.target.value,
    }));
  };

  const handleClose = useCallback(
    () => setBuildDialogState((prev) => ({ ...prev, open: false })),
    []
  );

  const confirmHandler = useCallback(async () => {
    const buildId =
      buildDialogState.selectedBuildId != ""
        ? buildDialogState.selectedBuildId
        : buildsQuery.data?.[0].id;

    if (buildDialogState.submittingTitle.length > 0) {
      setBuildDialogState((prev) => ({ ...prev, submitting: true }));
    }
    await buildDialogState.handleConfirm(buildId!);
    setBuildDialogState((prev) => ({ ...prev, open: false }));
    await delay(1000);
    setBuildDialogState(RESET);
  }, [buildDialogState, buildsQuery.data]);

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth={true}
      open={buildDialogState.open}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Select a build</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={"flex flex-col gap-3.5"}>
        {buildsQuery.isLoading && <Skeleton variant="rounded" height={56} />}
        {buildsQuery.isError && (
          <Alert
            severity={"error"}
            action={
              <Button color="inherit" size="small">
                RETRY
              </Button>
            }
            onClick={() => buildsQuery.refetch()}
          >
            Something went wrong while fetching builds
          </Alert>
        )}
        {!buildsQuery.isLoading && !buildsQuery.isError && (
          <FormControl fullWidth>
            <InputLabel id="builds-label">Builds</InputLabel>
            <Select
              fullWidth
              labelId={"builds-label"}
              label={"Builds"}
              autoWidth={true}
              value={
                buildDialogState.selectedBuildId != ""
                  ? buildDialogState.selectedBuildId
                  : buildsQuery.data?.[0]?.id ?? ""
              }
              onChange={handleChange}
              disabled={buildsQuery.data?.length == 0}
            >
              {buildsQuery.data &&
                buildsQuery.data?.map((item) => {
                  const metaDataString = JSON.stringify(item.metaData, null, 1)
                    .replace(/\n/g, "")
                    .replace("}", " }");
                  const parsedMetadata =
                    metaDataString.length < 50
                      ? metaDataString
                      : `${metaDataString.substring(0, 50)} ... }`;

                  return (
                    <MenuItem key={item.id} id={item.id} value={item.id}>
                      <div>
                        {DateTime.fromMillis(item?.epoch * 1000).toFormat(
                          "yyyy-MM-dd hh:mm:ss a"
                        )}
                        {item?.is_group === true
                          ? ` - ${item?.platform} - AppGroup`
                          : ` - ${item?.platform} - ${item.app_name}`}
                        {item?.is_group === true
                          ? item.app_name
                              .split(" + ")
                              .map((name, index) => (
                                <p key={index}>
                                  {`${name} - ${
                                    item?.appVersionName.split("\n")[index]
                                  }${
                                    item.appVersionCode != null
                                      ? ` - ${
                                          item.appVersionCode.split("\n")[index]
                                        }`
                                      : ""
                                  }`}
                                </p>
                              ))
                          : ` - ${item.appVersionName}${
                              item.appVersionCode != null
                                ? ` - ${item.appVersionCode}`
                                : ""
                            }`}
                        {item?.metaData &&
                          Object.keys(item.metaData).length > 0 && (
                            <p className={"text-gray-500"}>
                              Meta: {parsedMetadata}
                            </p>
                          )}
                      </div>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
        {buildDialogState.actionTitle.includes("Run test") && (
          <>
            <Alert severity={"warning"}>
              <p>
                {"Runs will use devices configured in "}
                <Link
                  to={"/gpt-driver/settings"}
                  state={{ settingsSection: "devices" }}
                  className={"font-bold"}
                  onClick={handleClose}
                >
                  {"Settings > Devices > Cloud"}
                </Link>
              </p>
            </Alert>
            <Alert severity={"info"}>
              <p className={"text-[14px]"}>
                {"To trigger test runs programmatically, please refer to "}
                <Link
                  target="_blank"
                  to={"https://gpt-driver.readme.io/docs/upload-new-build"}
                >
                  <span className="underline hover:cursor-pointer">
                    our guide about CI/CD Integration
                  </span>
                </Link>
                {" as well as "}
                <Link
                  target="_blank"
                  to={"https://gpt-driver.readme.io/reference/executetests"}
                >
                  <span className="underline hover:cursor-pointer">
                    our API Documentation.
                  </span>
                </Link>
              </p>
            </Alert>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          disabled={
            buildDialogState.submitting ||
            buildsQuery.isLoading ||
            buildsQuery.isError
          }
          onClick={confirmHandler}
        >
          {buildDialogState.submitting && (
            <p>{buildDialogState.submittingTitle}</p>
          )}
          {!buildDialogState.submitting && buildDialogState.actionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const buildDialogStateAtom = atomWithReset({
  handleConfirm: async (buildId: string) => {},
  open: false,
  actionTitle: "",
  selectedBuildId: "",
  submittingTitle: "",
  submitting: false,
});

export { SelectBuildDialog, buildDialogStateAtom };
