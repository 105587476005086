import { atomFamily } from "jotai/utils";
import { atomWithQuery } from "jotai-tanstack-query";
import { doc, getDoc } from "firebase/firestore";
import { customFirestore } from "../firebase";
import { BuildData } from "../utils/types";
import getBuildsAtom from "./get-builds-atom";

const buildDataAtom = atomFamily(
  ({
    buildId,
    organizationId,
  }: {
    buildId?: string;
    organizationId?: string;
  }) => {
    return atomWithQuery((get) => {
      let processedBuildId = buildId;
      let lastBuildQuery;
      if (organizationId != null) {
        lastBuildQuery = get(
          getBuildsAtom({ organizationId: organizationId, limit: 20 })
        );
        if (buildId == null) {
          processedBuildId = lastBuildQuery.data?.[0]?.id;
        }
      }

      return {
        queryKey: ["buildData", processedBuildId],
        queryFn: async () => {
          const uploadDocRef = doc(
            customFirestore,
            "uploads",
            processedBuildId!
          );
          const uploadSnap = await getDoc(uploadDocRef);
          if (uploadSnap.exists()) {
            if (buildId == null) {
              const lastBuild = lastBuildQuery.data[0];
              updateBuildId(lastBuild.id);
            }
            const buildData = uploadSnap.data() as BuildData;
            return { id: uploadSnap.id, ...buildData };
          } else {
            if (!!lastBuildQuery?.data) {
              const lastBuild = lastBuildQuery.data[0];
              if (lastBuild) {
                updateBuildId(lastBuild.id);
                return lastBuild;
              }
            }

            throw Error("Build doesn't exist");
          }
        },
        enabled:
          (organizationId != null && !!lastBuildQuery?.data) ||
          (organizationId == null && buildId != null) ||
          (organizationId != null && buildId != null && !!lastBuildQuery?.data),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      };
    });
  },
  (a, b) => a.buildId === b.buildId && a.organizationId === b.organizationId
);

function updateBuildId(newBuildId) {
  try {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/").filter(Boolean);

    let newPath;

    // Determine the new path based on current format
    if (pathParts.includes("build")) {
      // Case 1: "build/:buildId/test/:testId?" or "build/:buildId"
      const buildIndex = pathParts.indexOf("build");
      pathParts[buildIndex + 1] = newBuildId; // Update buildId

      if (pathParts[buildIndex + 2] === "test" && pathParts[buildIndex + 3]) {
        newPath = `/build/${newBuildId}/test/${pathParts[buildIndex + 3]}`; // Format 1
      } else {
        newPath = `/build/${newBuildId}`; // Format 2
      }
    } else if (pathParts.includes("test")) {
      // Case 3: "test/:testId"
      const testId = pathParts[pathParts.indexOf("test") + 1];
      newPath = `/build/${newBuildId}/test/${testId}`;
    } else {
      // Handle unexpected or empty paths (optional logic)
      newPath = `/build/${newBuildId}`;
    }

    // Replace the current history state with the new path
    history.replaceState(null, "", `/gpt-driver${newPath}`);
  } catch (e) {}
}

export default buildDataAtom;
