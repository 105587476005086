import {
  changeCompanyCustomClaimsSetter,
  customFirestore,
  customStorage,
} from "../firebase";
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "@firebase/firestore";
import {
  CachedStepsSavedData,
  CompanySettings,
  FirestoreSetting,
  Invitation,
  MemberWithStatus,
  ModelVersions,
  TemplateWithLabel,
} from "./types";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { removeItemFromArray, trackSentryException } from "./helpers";
import { FirebaseError } from "@firebase/util";

const getCompanyNameFromOrganisationId = async (
  organisationId: string
): Promise<string | undefined> => {
  const companyQuery = query(
    collection(customFirestore, "companies"),
    where("id", "==", organisationId)
  );
  const result = await getDocs(companyQuery);

  if (result.empty) {
    console.error(`Company document not found for id = ${organisationId}`);
    return undefined;
  }

  return result.docs[0].id;
};

async function getInvites(organisationId: string): Promise<Invitation[]> {
  console.log("Getting invites for company", organisationId);

  const invitations = await getDocs(
    query(
      collection(customFirestore, "invitations"),
      where("organisationId", "==", organisationId)
    )
  );
  return invitations.docs.map<Invitation>((invitation) => ({
    id: invitation.id,
    ...(invitation.data() as Omit<Invitation, "id">),
  }));
}

async function getModelVersionForCompany(
  companyId: string | undefined
): Promise<ModelVersions | undefined> {
  if (companyId != null) {
    const result = await getDocs(
      query(
        collection(customFirestore, "companies"),
        where("id", "==", companyId)
      )
    );
    const companyDoc = result.docs[0];

    return companyDoc.data()?.model_version as ModelVersions;
  } else {
    console.log("No company passed");
    return undefined;
  }
}

async function getMembers(organisationId: string): Promise<MemberWithStatus[]> {
  console.log("Getting members for company", organisationId);

  const invites = await getInvites(organisationId);
  const userQuery = query(
    collection(customFirestore, "users"),
    where("organisationId", "==", organisationId)
  );

  const users = await getDocs(userQuery);
  const registeredUsers = users.docs.map<MemberWithStatus>((user) => ({
    registered: true,
    ...(user.data() as Omit<MemberWithStatus, "registered">),
  }));
  const unregisteredUsers = invites.reduce<MemberWithStatus[]>(
    (prev, currentInvite) => {
      const isRegistered =
        registeredUsers.filter((user) => user.email === currentInvite.email)
          .length > 0;

      if (!isRegistered) {
        return [
          ...prev,
          {
            email: currentInvite.email,
            registered: false,
          },
        ];
      }
      return prev;
    },
    []
  );

  return [...unregisteredUsers, ...registeredUsers];
}

const getUserDocByEmail = async (email: string) => {
  const userQuery = query(
    collection(customFirestore, "users"),
    where("email", "==", email)
  );
  const result = await getDocs(userQuery);

  if (result.empty) {
    console.error(`User document not found for user ${email}`);
    return null;
  }

  return result.docs[0];
};

const getUsersRole = async (
  email: string | null
): Promise<undefined | string> => {
  if (!email) {
    return undefined;
  }
  const userDoc = await getUserDocByEmail(email);

  return userDoc ? userDoc.data()?.role : undefined;
};

const updateUsersCompany = async (organisationId: string) => {
  await changeCompanyCustomClaimsSetter({ organisationId });
};

const getTemplatesForCompany = async (
  companyName: string | undefined
): Promise<TemplateWithLabel[]> => {
  if (companyName) {
    console.log("Getting templates for company", companyName);

    const companyDoc = await getDoc(
      doc(customFirestore, "companies", companyName)
    );
    return companyDoc.data()?.templates ?? [];
  } else {
    console.log("No company passed");
    return [];
  }
};

const editTemplateLabel = async (
  companyName: string | undefined,
  oldLabel: string,
  newLabel: string
) => {
  if (oldLabel === newLabel) {
    console.log("Labels the same, returning early");
    return;
  }

  if (companyName) {
    console.log(
      `Changing template name from ${oldLabel} to ${newLabel} for company ${companyName}`
    );
    const docRef = doc(customFirestore, "companies", companyName);
    const templates: TemplateWithLabel[] = (await getDoc(docRef)).data()
      ?.templates;
    const foundTemplate = templates.find(
      (template) => template.label == oldLabel
    );

    if (foundTemplate) {
      const updatedTemplate = {
        imageUrl: foundTemplate?.imageUrl,
        label: newLabel,
      };
      await updateDoc(docRef, { templates: arrayRemove(foundTemplate) });
      await updateDoc(docRef, { templates: arrayUnion(updatedTemplate) });
    } else {
      console.log(`Template with label ${oldLabel} not found`);
    }
  } else {
    console.log("No company passed");
    return;
  }
};

const deleteTemplate = async (
  companyName: string | undefined,
  imageUrl: string
) => {
  if (companyName) {
    console.log(`Deleting template ${imageUrl} for company ${companyName}`);
    const docRef = doc(customFirestore, "companies", companyName);
    const templates: TemplateWithLabel[] = (await getDoc(docRef)).data()
      ?.templates;
    const foundTemplate = templates.find(
      (template) => template.imageUrl == imageUrl
    );

    if (foundTemplate) {
      try {
        await deleteObject(ref(customStorage, imageUrl));
      } catch (error) {
        trackSentryException(error);
        if (error instanceof FirebaseError) {
          if (error.code !== "storage/object-not-found") {
            throw error;
          }
        } else {
          throw error;
        }
      }
      await updateDoc(docRef, { templates: arrayRemove(foundTemplate) });
    } else {
      console.log(`Template with url ${imageUrl} not found`);
    }
  } else {
    console.log("No company passed");
    return;
  }
};

const sendTemplateToFirebaseStorage = async (
  companyName: string | undefined,
  label: string,
  file: File
): Promise<string> => {
  if (!companyName) {
    throw new Error("Company is undefined!");
  }
  console.log(
    `Sending template ${label} to storage for company ${companyName}`
  );

  const date = new Date();

  const fileName = `templates/${companyName}/${Math.ceil(
    date.getTime() / 1000
  )}-template-${file.name}`;
  const templatesRef = ref(customStorage, fileName);
  const uploadedFile = await uploadBytes(templatesRef, file);
  return await getDownloadURL(uploadedFile.ref);
};

const addTemplateToFirestoreCompanyDoc = async (
  companyName: string | undefined,
  imageUrl: string,
  label: string,
  device: string
): Promise<boolean> => {
  if (companyName) {
    console.log(`Adding template ${label} for company`, companyName);
    const companyDoc = doc(customFirestore, "companies", companyName);
    const newTemplate: TemplateWithLabel = { imageUrl, label, device };
    await updateDoc(companyDoc, { templates: arrayUnion(newTemplate) });

    return true;
  } else {
    console.log("No company passed");

    return false;
  }
};

const getCompanySettings = async (companyId: string | undefined) => {
  if (companyId) {
    console.log(`Getting settings for companyId = ${companyId}`);
    const settingsDoc = await getDoc(
      doc(customFirestore, "settings", companyId)
    );
    const settingsData = settingsDoc?.data();

    if (settingsData) {
      return settingsData as FirestoreSetting;
    }
  }

  console.log(`No settings found for company id = ${companyId}`);
  return undefined;
};

const saveSettingsToFirestore = async (
  companyId: string | undefined,
  settings: CompanySettings
) => {
  if (companyId) {
    console.log(`Getting settings for companyId = ${companyId}`);
    const settingsRef = doc(customFirestore, "settings", companyId);
    const newSettings = {};

    for (const [key, field] of Object.entries(settings)) {
      console.log(key, field.value);
      if (!field.disabled) {
        newSettings[key] = field.value;
      }
    }

    await updateDoc(settingsRef, newSettings);
  } else {
    throw new Error(
      `Settings document not found for company Id = ${companyId}`
    );
  }
};

const saveSettingsToFirestore2 = async (
  companyId: string | undefined,
  settings: CompanySettings
) => {
  if (companyId) {
    console.log(`Getting settings for companyId = ${companyId}`, settings);
    const settingsRef = doc(customFirestore, "settings", companyId);

    await updateDoc(settingsRef, settings);
  } else {
    throw new Error(
      `Settings document not found for company Id = ${companyId}`
    );
  }
};

const deleteCachedSteps = async (
  testId: string | undefined,
  deleteAll: boolean,
  platform: string,
  device: string,
  stepNumber: number = 0
): Promise<CachedStepsSavedData> => {
  console.log(`Deleteing ${deleteAll && "all"} steps for test ${testId}`);
  if (testId) {
    const testRef = doc(customFirestore, "custom-tests", testId);
    const docSnap = await getDoc(testRef);
    let cachedData: CachedStepsSavedData = docSnap.data()?.cachedSteps ?? {};

    if (deleteAll) {
      cachedData[platform][device] = [];
      console.log(`Removed all cached steps from test ${testId}`);
    } else {
      cachedData[platform][device] = removeItemFromArray(
        cachedData[platform][device],
        stepNumber
      ); // remove item at specified index

      console.log("Cached data after delete", cachedData);
      console.log(`Removed cached step ${stepNumber} from test ${testId}`);
    }

    await updateDoc(testRef, { cachedSteps: cachedData });

    return cachedData;
  }

  return {};
};

export {
  getMembers,
  updateUsersCompany,
  getTemplatesForCompany,
  deleteTemplate,
  editTemplateLabel,
  addTemplateToFirestoreCompanyDoc,
  sendTemplateToFirebaseStorage,
  getModelVersionForCompany,
  getUsersRole,
  getCompanyNameFromOrganisationId,
  getCompanySettings,
  saveSettingsToFirestore,
  deleteCachedSteps,
  getUserDocByEmail,
  saveSettingsToFirestore2,
};
