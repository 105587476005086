import { atomFamily, atomWithDefault } from "jotai/utils";
import { atom } from "jotai";
import { type BuildLogs } from "./utils/types";

const buildLogsAtomWithId = atomFamily((id?: string) => {
  return atomWithDefault((get) => {
    const buildLogs: BuildLogs = {
      logs: [],
      networkLogs: [],
      interactionLogs: [],
      videoFrames: [],
      audioFrames: [],
      getUIElements: [],
    };
    return buildLogs;
  });
});

const sessionAtom = atom<any>(null);
const appetizeClientAtom = atom<any>(null);

export { buildLogsAtomWithId, sessionAtom, appetizeClientAtom };
