import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DetailModal from "./detailModal";
import SyntaxHighlighter from "react-syntax-highlighter";
import {
  docco,
  stackoverflowDark,
} from "react-syntax-highlighter/dist/esm/styles/hljs";
import type { NetworkLog } from "../../utils/types";
import { styled as materialStyled } from "@mui/material/styles";
import { TabPanel } from "@mui/lab";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import { networkLogsCache, CustomFab, CustomList, Title } from "./components";
import { AutoSizer, CellMeasurer } from "react-virtualized";
import { getNextReplayFrameIndex } from "./session_info_tabs";
import { Divider, Zoom } from "@mui/material";
import { North, South } from "@mui/icons-material";
import { atom, useAtom, useAtomValue } from "jotai";

interface NetworkTabProps {
  networkLogs: NetworkLog[];
  playbackTimestamp: number;
  display: boolean;
}

interface SelectedNetworkLog {
  requestMethod?: string;
  requestUrl: string;
  responseCode?: string;
  obj?: object;
}

const NetworkTab = ({
  networkLogs,
  playbackTimestamp,
  display,
}: NetworkTabProps) => {
  const [selectedLog, setSelectedLog] = useState(null);

  const parsedLogs = useMemo(
    () =>
      networkLogs
        .filter(
          (networkLog) =>
            networkLog.type === "response" &&
            networkLog.request.method &&
            networkLog.request.url &&
            networkLog.response.status
        )
        .map((networkLog) => {
          return {
            requestMethod: networkLog.request.method,
            requestUrl: networkLog.request.url,
            responseCode: `${networkLog.response.status}`,
            obj: networkLog,
          };
        }),
    [networkLogs]
  );

  const listRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [jumpToTimestamp, setJumpToTimestamp] = useState<
    "hidden" | "top" | "bottom"
  >("hidden");
  const [scrollToIndex, setScrollToIndex] = useState<undefined | number>(
    undefined
  );

  useEffect(() => {
    if (
      parsedLogs?.at(0)?.obj?.relativeTimestamp != null &&
      playbackTimestamp <= parsedLogs?.at(0)?.obj?.relativeTimestamp!
    ) {
      setJumpToTimestamp("hidden");
    } else {
      const startIndex = listRef.current?.Grid._renderedRowStartIndex;
      const stopIndex = listRef.current?.Grid._renderedRowStopIndex;

      const someLogRenderedBeforeCurrentTimestamp = parsedLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.obj.relativeTimestamp <= playbackTimestamp);

      const someLogRenderedAfterCurrentTimestamp = parsedLogs
        ?.slice(startIndex, stopIndex + 1)
        ?.some((log) => log.obj.relativeTimestamp > playbackTimestamp);

      const lastItemDisplayed = stopIndex === parsedLogs?.length - 1;
      if (
        (someLogRenderedBeforeCurrentTimestamp &&
          someLogRenderedAfterCurrentTimestamp) ||
        (playbackTimestamp > parsedLogs?.at(-1)?.obj?.relativeTimestamp &&
          lastItemDisplayed)
      ) {
        setJumpToTimestamp("hidden");
      } else {
        const everyLogRenderedBeforeCurrentTimestamp = parsedLogs
          ?.slice(startIndex, stopIndex + 1)
          ?.every((log) => log.obj.relativeTimestamp <= playbackTimestamp);
        setJumpToTimestamp(
          everyLogRenderedBeforeCurrentTimestamp ? "bottom" : "top"
        );
        setScrollToIndex(undefined);
      }
    }
  }, [playbackTimestamp, parsedLogs, scrollTop]);

  const jumpToTimestampHandler = useCallback(() => {
    const indexToJump = parsedLogs?.findLastIndex(
      (log) => playbackTimestamp >= log.obj.relativeTimestamp
    );

    const extraItemExist = !!parsedLogs[indexToJump + 1];

    setScrollToIndex(
      jumpToTimestamp === "bottom"
        ? indexToJump + (extraItemExist ? 1 : 0)
        : indexToJump
    );
  }, [parsedLogs, playbackTimestamp, jumpToTimestamp]);

  const [forceMoveToTimestampNetworkTab, setForceMoveToTimestampNetworkTab] =
    useAtom(forceMoveToTimestampNetworkTabAtom);

  useEffect(() => {
    if (forceMoveToTimestampNetworkTab != null) {
      const indexToJump = parsedLogs?.findLastIndex(
        (log) => forceMoveToTimestampNetworkTab >= log.obj.relativeTimestamp
      );
      setScrollToIndex(indexToJump);
      setForceMoveToTimestampNetworkTab(null);
    }
  }, [forceMoveToTimestampNetworkTab]);

  return (
    <>
      <DetailModal
        open={!!selectedLog}
        handleClose={() => {
          setSelectedLog(null);
        }}
        title={"Network Log details"}
      >
        <>
          {selectedLog && (
            <SyntaxHighlighter language="json" style={stackoverflowDark}>
              {JSON.stringify(selectedLog, null, 2)}
            </SyntaxHighlighter>
          )}
        </>
      </DetailModal>
      <NetworkLogsTable keepMounted value={"1"} display={display}>
        <Title style={{ paddingLeft: "16px" }}>Time</Title>
        <Title>Method</Title>
        <Title>Code</Title>
        <Title>Request URL</Title>
        <Divider
          style={{
            gridArea: "divider",
            width: "100%",
            marginTop: "7.5px",
          }}
        />
        <NetworkLogsTableContent>
          <Zoom in={jumpToTimestamp != "hidden"} unmountOnExit>
            <CustomFab
              variant={"extended"}
              jumpToTimestamp={jumpToTimestamp}
              onClick={jumpToTimestampHandler}
            >
              {jumpToTimestamp !== "top" ? <South /> : <North />}
              Jump to current timestamp
            </CustomFab>
          </Zoom>
          <AutoSizer>
            {({ width, height }) => (
              <CustomList
                onScroll={(params) => {
                  setScrollTop(params.scrollTop);
                }}
                ref={listRef}
                // onRowsRendered={onRowsRendered}
                rowCount={parsedLogs.length}
                width={width}
                height={height}
                deferredMeasurementCache={networkLogsCache}
                rowHeight={networkLogsCache.rowHeight}
                scrollToIndex={scrollToIndex}
                rowRenderer={({ index, key, style, parent }) => {
                  const currentLog = parsedLogs[index];
                  const className =
                    index === parsedLogs.length - 1 &&
                    playbackTimestamp > currentLog.obj.relativeTimestamp
                      ? "afterLastItem"
                      : currentLog.obj.relativeTimestamp <= playbackTimestamp
                      ? "beforeCurrentTime"
                      : "afterCurrentTime";

                  return (
                    <CellMeasurer
                      key={key}
                      cache={networkLogsCache}
                      parent={parent}
                      columnIndex={0}
                      rowIndex={index}
                    >
                      {({ registerChild }) => (
                        <div
                          className={className}
                          style={{
                            ...style,
                            paddingBottom: "16px",
                            paddingTop: index === 0 ? "15.5px" : 0,
                          }}
                          ref={registerChild}
                          key={`consoleLogRows-${index}`}
                        >
                          <NetworkLog
                            playbackTimestamp={playbackTimestamp}
                            setLog={() => {
                              setSelectedLog(currentLog);
                            }}
                            key={`networkLogs-${index}`}
                            log={currentLog}
                          />
                        </div>
                      )}
                    </CellMeasurer>
                  );
                }}
              />
            )}
          </AutoSizer>
        </NetworkLogsTableContent>
      </NetworkLogsTable>
    </>
  );
};

const forceMoveToTimestampNetworkTabAtom = atom<null | number>(null);

const NetworkLogsTable = materialStyled(TabPanel)(
  ({ display }: { display: boolean }) =>
    display
      ? {
          display: "grid",
          gridTemplateAreas: `
    "time method code url"
    "divider divider divider divider"
    "table-content table-content table-content table-content"
  `,
          "--network-logs-table-grid-template-columns":
            "10ch 8ch 6ch minmax(0, 1fr)",
          gridTemplateColumns:
            "var(--network-logs-table-grid-template-columns)",
          gridTemplateRows: "auto auto minmax(0, 1fr)",
          "--network-logs-table-gap": "16px",
          columnGap: "var(--network-logs-table-gap)",
          padding: 0,
          paddingTop: "16px",
          marginTop: "var(--network-logs-table-gap)",
          justifyItems: "start",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07))",
          boxShadow:
            "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "4px",
          marginInline: "16px",
          marginBottom: "16px",
        }
      : {}
);

const NetworkLogsTableContent = styled.div`
  grid-area: table-content;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--network-logs-table-gap);
  width: 100%;

  .beforeCurrentTime + .afterCurrentTime::before {
    border-top: 3px solid #90caf9;
    content: "";
    left: 0px;
    position: absolute;
    top: -9.5px;
    width: 100%;
  }
`;

function NetworkLog({ log, setLog, playbackTimestamp }) {
  const handleClick = useCallback(() => {
    setLog(log);
  }, [log]);

  const className =
    log.obj.relativeTimestamp <= playbackTimestamp
      ? "beforeCurrentTime"
      : "afterCurrentTime";

  return (
    <NetworkLogContent onClick={handleClick} className={className}>
      <div
        style={{
          paddingLeft: "16px",
        }}
      >
        {DateTime.fromMillis(log.obj.absoluteTimestamp).toFormat("hh:mm:ss")}
      </div>
      <div>
        <span>{log.requestMethod}</span>
      </div>
      <div>{log.responseCode}</div>
      <RequestUrl>{log.requestUrl}</RequestUrl>
    </NetworkLogContent>
  );
}

const NetworkLogContent = styled.div`
  display: grid;
  grid-template-areas: "time method code url";
  grid-template-columns: var(--network-logs-table-grid-template-columns);
  justify-items: start;
  gap: var(--network-logs-table-gap);
  position: relative;
`;

const RequestUrl = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export { forceMoveToTimestampNetworkTabAtom };

export default NetworkTab;
