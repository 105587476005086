import React, { useCallback, useContext } from "react";
import { AuthContext } from "../auth/AuthContext";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RenderLoginUI } from "./protectedRoute";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";
import styled from "@emotion/styled";

const AuthenticatedRoute = ({ children }) => {
  const useAuth = () => useContext<any>(AuthContext);
  const { user ,loading, loadingUser, signInWithToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const customAuthToken = searchParams.get("token");
  const autorunParam = searchParams.get("autorun");

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  useQuery({
    queryKey: ["signInWithCustomToken"],
    queryFn: async () => {
      await signInWithToken(customAuthToken);
      navigateTo(
        autorunParam === "true"
          ? `${location.pathname}?autorun=true`
          : location.pathname
      );
      return true;
    },
    enabled:
      user?.email == null && loadingUser === false && customAuthToken != null,
  });

  if (loading || loadingUser) {
    return (
      <SpinnerWrapper>
        <CircularProgress />
      </SpinnerWrapper>
    );
  }

  if (user.uid !== null) {
    return children;
  } else {
    return <RenderLoginUI />;
  }
};

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

export { AuthenticatedRoute };
