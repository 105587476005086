import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-tailwind/react";

import "./index.css";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./error-page";
import { Provider } from "jotai";
import { buildLogsAtomWithId } from "./atoms";
import { type TestSuiteLoaderApp, TestSuiteRecordingApp } from "./utils/types";
import { TestBuilderPage } from "./routes/test-builder-page";
import { queryClientAtom } from "jotai-tanstack-query";
import { AuthContextProvider } from "./auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OnboardingCallPage from "./routes/onboarding-call-page";
import GlobalSettings from "./routes/global-settings-page";
import { TestRecordingPage } from "./routes/recording/test-recording-page";
import { CommandList } from "./components/command-list";
import {
  testRunExecutionDataAtom,
  testRunRecordingDataAtom,
  testSuiteDataAtom,
} from "./routes/test-run/test-run-atoms";
import { useHydrateAtoms } from "jotai/utils";
import { TestRunBuilderPage } from "./routes/test-run/test-run-builder-page";
import GptDriverApp from "./routes/gpt-driver-app";
import TestsHome from "./routes/tests-home/tests-home";
import ReportsPage from "./routes/reports/reports-page";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider as MaterialUiThemeProvider } from "@mui/material/styles";
import BuildsHome from "./routes/builds-home/builds-home";
import { lightTheme } from "./themes";
import Session from "./routes/sessions/session";
import SessionsPage from "./routes/sessions/sessions";
import SessionAssistant from "./routes/sessions/assistant";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthContextProvider />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/onboardingCall/",
        element: <OnboardingCallPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/gpt-driver",
        element: <GptDriverApp />,
        errorElement: <ErrorPage />,
        children: [
          {
            path: "builds",
            element: <BuildsHome />,
          },
          {
            path: "sessions",
            element: <SessionsPage />,
          },
          {
            path: "sessions/assistant",
            element: <SessionAssistant />,
          },
          {
            path: "sessions/:sessionId",
            element: <Session />,
          },
          {
            path: "build/:buildId",
            element: <TestBuilderPage />,
            loader: ({ params, request }) => ({
              buildLogsData: buildLogsAtomWithId(params.buildId),
            }),
          },
          {
            path: "build/:buildId/test/:testId?",
            element: <TestBuilderPage />,
            loader: ({ params, request }) => ({
              buildLogsData: buildLogsAtomWithId(params.buildId),
            }),
          },
          {
            path: "test/:testId",
            element: <TestBuilderPage />,
            loader: ({ params, request }) => ({
              buildLogsData: buildLogsAtomWithId(undefined),
            }),
          },
          {
            path: "reports",
            element: <ReportsPage />,
          },
          {
            path: "settings",
            element: <GlobalSettings />,
          },
          {
            path: "tests",
            element: <TestsHome />,
          },
          {
            path: "?*",
            element: <Navigate to="tests" />,
          },
        ],
      },
      {
        path: "/tests-suites/:testSuiteId/runs/:runId",
        element: <GptDriverApp />,
        errorElement: <ErrorPage />,
        shouldRevalidate: () => false,
        children: [
          {
            path: "",
            loader: ({ params, request }): TestSuiteLoaderApp => ({
              testSuiteDataAtom: testSuiteDataAtom(params.testSuiteId!),
              testRunDataAtom: testRunExecutionDataAtom({
                suiteId: params.testSuiteId!,
                runId: params.runId!,
              }),
            }),
            element: <TestRunBuilderPage />,
          },
        ],
      },
      {
        path: "/recording/:testSuiteId/runs/:runId",
        element: <GptDriverApp />,
        errorElement: <ErrorPage />,
        shouldRevalidate: () => false,
        children: [
          {
            path: "",
            loader: ({ params, request }): TestSuiteRecordingApp => ({
              testSuiteDataAtom: testSuiteDataAtom(params.testSuiteId),
              testRunDataAtom: testRunRecordingDataAtom({
                suiteId: params.testSuiteId!,
                runId: params.runId!,
              }),
            }),
            element: <TestRecordingPage />,
          },
        ],
      },
      {
        path: "/command-list-test",
        element: <CommandList />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

const queryClient = new QueryClient();

const HydrateAtoms = ({ children }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return children;
};

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <MaterialUiThemeProvider theme={lightTheme}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider>
        <ThemeProvider>
          <HydrateAtoms>
            <RouterProvider router={router} />
            <ToastContainer />
          </HydrateAtoms>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </MaterialUiThemeProvider>
  // </React.StrictMode>
);
