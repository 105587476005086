import React, { useState, useCallback } from "react";
import axios from "axios";
import { Tooltip, Button, Alert } from "@material-tailwind/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { trackSentryException } from "../utils/helpers";

const isValidEmail = (email) => {
  // Email validation regex pattern
  const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return pattern.test(email);
};

const FileUpload = ({ setUploadComplete }) => {
  const [file, setFile] = useState(null);
  const [email, setEmail] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  // console.log("File: ", file);
  // console.log("Email: ", email);
  // console.log("!file: ", !file);
  // console.log("isValidEmail: ", isValidEmail(email));

  const onFileDrop = useCallback((event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
  }, []);

  const onFileDragOver = (event) => {
    event.preventDefault();
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const onSubmit = async (event) => {
    // console.log("onSubmit File: ", file);
    // console.log("onSubmit Email: ", email);
    // console.log("onSubmit !file: ", !file);
    // console.log("onSubmit isValidEmail: ", isValidEmail(email));
    event.preventDefault();
    if (!file) {
      alert("Please provide a valid file.");
      return;
    } else if (!isValidEmail(email)) {
      alert("Please provide a valid email.");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append("build", file);
    formData.append("uploader_id", email);
    formData.append("platform", "android");
    formData.append("metadata", "{}");

    try {
      const response = await axios.post(
        "https://api.flutterboost.com/uploadAppFile/",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        setUploadComplete(true);
      } else {
        setUploadComplete(true);
      }
    } catch (error) {
      trackSentryException(error);
      console.error("Error:", error);
      alert("Failed to upload your file. Please try again.");
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="file-upload mt-8">
      <h1 className="text-lg font-medium mb-4">Upload app file</h1>
      <form onSubmit={onSubmit}>
        <label
          htmlFor="file"
          className="block font-medium text-blue-400 underline inline mt-4"
        >
          Select a file
        </label>
        <span className="text-gray-700">
          {" "}
          or drag & drop it in the area below
        </span>
        <div
          className="drop-zone border-2 text-center border-dashed border-gray-500 mt-4 p-4 mb-4 bg-gray-200 text-gray-500 font-bold cursor-pointer rounded"
          onDrop={onFileDrop}
          onDragOver={onFileDragOver}
        >
          {file && <span className="text-black">{file.name}</span>}
          {!file && "Drag and drop an app build here"}
        </div>
        <input
          type="file"
          id="file"
          name="build"
          onChange={onFileChange}
          className="hidden"
        />
        <Alert
          color="amber"
          icon={<InformationCircleIcon strokeWidth={2} className="h-6 w-6" />}
        >
          Supported file types:
          <br />
          <b>.apk (debug build)</b> for Android
          <br />
          <b>.zip</b> containing compressed <b>.app bundle (simulator build)</b>{" "}
          for iOS
        </Alert>
        <label htmlFor="email" className="block text-lg font-medium mt-8">
          Email address:
        </label>
        <input
          type="email"
          value={email}
          onChange={onEmailChange}
          placeholder="Your email address"
          className={`mt-1 block w-full border border-gray-800 rounded-md shadow-sm p-2 ${
            !isValidEmail(email) && email.length > 0 ? "border-red-400" : ""
          }`}
          required
        />
        {isUploading && (
          <div className="relative pt-1 w-full mt-8">
            <div className="overflow-hidden h-2 text-xs flex rounded bg-green-200">
              <div
                style={{ width: `${uploadProgress}%` }}
                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500 transition-all duration-300"
              ></div>
            </div>
          </div>
        )}
        <button
          type="submit"
          disabled={!file || isUploading}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded cursor-pointer mt-8 disabled:opacity-50"
        >
          {isUploading ? "Uploading..." : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default FileUpload;
