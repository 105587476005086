import React, { useCallback, useContext, useRef, useState } from "react";
import { AuthContext } from "../../auth/AuthContext";
import { useQueryClient } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { customAlertStateAtom } from "../../components/custom-alert";
import Button from "@mui/material/Button";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { trackSentryException } from "../../utils/helpers";
import getBuildsAtom from "../../atoms/get-builds-atom";

const UploadBuild = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const { user } = useContext<any>(AuthContext);
  const queryClient = useQueryClient();

  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  const handleUploadClick = useCallback((e) => {
    e.preventDefault();
    fileInput.current?.click();
  }, []);

  const handleFileChange = useCallback((e) => {
    console.log(e);
    handleUpload(e.target.files[0]);
  }, []);

  const handleUpload = useCallback(async (selectedFile: File) => {
    if (!selectedFile) {
      alert("Please select a file");
      return;
    }
    console.log("selected file", selectedFile);
    let platform = "android";
    const fileExtension = selectedFile.name.split(".").pop();
    if (fileExtension === "zip" || fileExtension === "gz") {
      platform = "ios";
    } else if (fileExtension === "apk") {
      platform = "android";
    } else {
      alert(`Incorrect file type passed: ${fileExtension}`);
      return;
    }

    console.log("File extension = ", fileExtension);

    setIsUploading(true);

    const formData = new FormData();
    formData.append("build", selectedFile);
    formData.append("organisation_key", user.companyId);
    formData.append("platform", platform);
    formData.append("metadata", JSON.stringify({}));

    try {
      const response = await axios.post(
        "https://api.mobileboost.io/uploadBuild/",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.progress ?? 0) * 100
            );
            setUploadProgress(percentCompleted);
          },
        }
      );

      if (response.status === 200) {
        queryClient.removeQueries({
          queryKey: ["getBuilds", user.companyId],
        });
        getBuildsAtom.remove({ organizationId: user.companyId, limit: 20 });
        await queryClient.invalidateQueries({
          queryKey: ["getPaginatedBuilds"],
        });
        setCustomAlertState({
          open: true,
          type: "success",
          title: "Success",
          description:
            "The build file has been uploaded successfully and will be available shortly (within 2 min)",
        });
      } else {
        setCustomAlertState({
          open: true,
          type: "error",
          title: "Failure",
          description: response.statusText,
        });
      }
    } catch (error) {
      trackSentryException(error);
      console.error("Error:", error);
      setCustomAlertState({
        open: true,
        type: "error",
        title: "Failure",
        description: "Failed to upload your file. Please try again.",
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  }, []);

  return (
    <>
      <input
        type="file"
        onChange={handleFileChange}
        ref={fileInput}
        style={{ display: "none" }}
      />
      <Button
        variant={"outlined"}
        disabled={isUploading}
        onClick={handleUploadClick}
        sx={{
          ":disabled": {
            borderColor: "#000000de",
            ".MuiButton-startIcon": {
              color: "#000000de",
            },
            color: "#000000de",
          },
        }}
        startIcon={isUploading ? null : <Add />}
      >
        {isUploading && (
          <p>
            Uploading... <span className={"font-bold"}>{uploadProgress}%</span>
          </p>
        )}
        {!isUploading && "Upload Build"}
      </Button>
    </>
  );
};

export default UploadBuild;
