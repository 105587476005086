import React, { useCallback, useContext } from "react";
import {
  Breadcrumbs,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../auth/AuthContext";
import { UserType } from "../utils/types";
import { useQuery } from "@tanstack/react-query";
import { getCompanies } from "../auth/operations";
import { updateUsersCompany } from "../utils/firebase_operations";
import { useAtom, useAtomValue } from "jotai";
import getBuildsAtom from "../atoms/get-builds-atom";
import TextField from "@mui/material/TextField";
import { Button, MenuItem, Select } from "@mui/material";
import { Help } from "@mui/icons-material";

const CompaniesChangeDropdown = ({
  companies,
  user,
}: {
  companies: any[];
  user: UserType;
}) => {
  return (
    <>
      {companies && (
        <Select
          value={user.companyId}
          label=""
          onChange={async (value) => {
            await updateUsersCompany(value.target.value);
            location.reload();
          }}
        >
          {companies.map((company) => (
            <MenuItem key={company.id} value={company.id} id={company.id}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

const LogoutButton = ({
  user,
  logoutHandler,
}: {
  user: UserType;
  logoutHandler: () => any;
}) => {
  return (
    <Popover placement="bottom-end">
      <PopoverHandler>
        <button
          className={`h-8 ml-4 mr-4 text-gray-600 font-semibold rounded-full hover:text-gray-700`}
        >
          <div className={"flex justify-between gap-x-2"}>
            {user.email}
            <ChevronDownIcon className={"text-black h-6 w-6 "} />
          </div>
        </button>
      </PopoverHandler>
      <PopoverContent className="py-4 z-50">
        <div className={"flex flex-col w-full gap-3"}>
          {user.role === "superUser" ? (
            <>{user.companyName && <CompanyList user={user} />}</>
          ) : (
            <TextField disabled={true} value={user.companyName} />
          )}
          <Button variant={"contained"} size={"large"} onClick={logoutHandler}>
            Logout
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
};

const CompanyList = ({ user }: { user: UserType }) => {
  const { isLoading, data, isError, error } = useQuery({
    queryKey: ["companyNames"],
    queryFn: () => getCompanies(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return (
    <>{data && <CompaniesChangeDropdown companies={data} user={user} />}</>
  );
};

const TestingAppBar = ({ headerLabel }: { headerLabel?: string }) => {
  const navigate = useNavigate();
  const useAuth = () => useContext<any>(AuthContext);
  const { user, logOut } = useAuth();

  const lastBuildQuery = useAtomValue(
    getBuildsAtom({ organizationId: user.companyId, limit: 20 })
  );

  const navigateTo = useCallback(
    (path: string) => {
      navigate(path);
    },
    [navigate]
  );

  return (
    <>
      {!user.isAnonymous && (
        <div className="justify-between flex flex-row w-full bg-white items-center border-b border-l py-2 pl-4 lg:pl-8 lg:py-4 h-[61px]">
          {headerLabel !== undefined &&
          headerLabel.toLowerCase() !== "settings" &&
          headerLabel.toLowerCase() !== "reports" ? (
            <Breadcrumbs
              separator={
                <ChevronRightIcon className={"text-[#7C7C7C] h-5 w-5 "} />
              }
              className={"bg-transparent  p-0 m-0"}
            >
              <a
                className="text-[16px] text-[#4890FB] hover:cursor-pointer"
                onClick={() => {
                  navigateTo(`/gpt-driver/tests`);
                }}
              >
                All tests
              </a>
              {headerLabel !== undefined && (
                <a href="#" className="text-black text-[16px]">
                  {headerLabel}
                </a>
              )}
            </Breadcrumbs>
          ) : (
            <div></div>
          )}
          <div className="flex justify-between gap-2.5">
            <Button
              onClick={() => {
                window.Pylon("show");
              }}
              variant="outlined"
              startIcon={<Help />}
            >
              Help
            </Button>
            <div className={"flex justify-between"}>
              {!lastBuildQuery.isLoading &&
                lastBuildQuery.data &&
                lastBuildQuery.data[0]?.iconUrl && (
                  <img
                    src={lastBuildQuery.data[0]?.iconUrl}
                    className="w-10 h-10 rounded-2xl"
                    alt={"logo"}
                  />
                )}
              <LogoutButton user={user} logoutHandler={logOut} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TestingAppBar;
