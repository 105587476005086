const LAMBDA_AWS_URL =
  "https://b6atcrsoulmqnxiyzoocg5ctli0kmicz.lambda-url.us-east-1.on.aws/";

const LAMBDA_AWS_TIMEOUT = 60000;

const BACKEND_SERVER_URL = "https://tester-eu.mobileboost.io";

const FASTAPI_SERVER_URL = "https://api.mobileboost.io";

export {
  LAMBDA_AWS_URL,
  LAMBDA_AWS_TIMEOUT,
  BACKEND_SERVER_URL,
  FASTAPI_SERVER_URL,
};
