import * as React from "react";
import {
  DataGrid,
  GridRowId,
  GridPaginationModel,
  GridColDef,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Dispatch, SetStateAction, useCallback } from "react";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { BuildUploadData, PaginatedResponse } from "../utils/types";
import CustomNoRowsOverlay from "./custom-now-rows-overlay";

const CursorPaginationGrid = ({
  setPaginationModel,
  paginationModel,
  queryResults,
  mapPageToNextCursor,
  columns,
  toolbarActions,
  noRowsTitle,
}: {
  setPaginationModel: Dispatch<
    SetStateAction<{
      page: any;
      pageSize: number;
    }>
  >;
  paginationModel: {
    page: any;
    pageSize: number;
  };
  queryResults: UseQueryResult<PaginatedResponse<BuildUploadData>, Error>;
  mapPageToNextCursor: React.MutableRefObject<{ [page: number]: GridRowId }>;
  columns: GridColDef[];
  toolbarActions?: React.JSXElementConstructor<any>;
  noRowsTitle?: string;
}) => {
  const pageInfo = queryResults.data?.pageInfo;
  const handlePaginationModelChange = useCallback(
    (newPaginationModel: GridPaginationModel) => {
      // We have the cursor, we can allow the page transition.
      if (
        newPaginationModel.page === 0 ||
        mapPageToNextCursor.current[newPaginationModel.page - 1]
      ) {
        console.log(newPaginationModel);
        setPaginationModel(newPaginationModel);
      }
    },
    []
  );

  React.useEffect(() => {
    if (!queryResults.isLoading && pageInfo?.nextCursor) {
      // We add nextCursor when available
      mapPageToNextCursor.current[paginationModel.page] = pageInfo?.nextCursor;
    }
  }, [paginationModel.page, queryResults.isLoading, pageInfo?.nextCursor]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  const [rowCountState, setRowCountState] = React.useState(
    pageInfo?.totalRowCount || 0
  );
  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.totalRowCount !== undefined
        ? pageInfo?.totalRowCount
        : prevRowCountState
    );
  }, [pageInfo?.totalRowCount, setRowCountState]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    // Here you save the data you need from the filter model
    console.log("filterModel", filterModel);
  }, []);

  return (
    <DataGrid
      className={"bg-white"}
      loading={queryResults.isLoading}
      rows={queryResults.data?.data ?? []}
      columns={columns}
      showCellVerticalBorder={true}
      slots={{ toolbar: toolbarActions, noResultsOverlay: CustomNoRowsOverlay }}
      rowCount={rowCountState}
      paginationMode="server"
      onPaginationModelChange={handlePaginationModelChange}
      paginationModel={paginationModel}
      pageSizeOptions={[10, 25, 50, 100]}
      onFilterModelChange={onFilterChange}
      hideFooterSelectedRowCount={true}
      disableRowSelectionOnClick={true}
      checkboxSelection={false}
      slotProps={{ noResultsOverlay: { title: noRowsTitle } }}
    />
  );
};

export default CursorPaginationGrid;
