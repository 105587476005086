import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";

import { Session as SessionType } from "../../utils/types";

import { customFirestore } from "../../firebase";
import { CircularProgress } from "@mui/material";
import Info from "./info";
import Content from "./content";

const Session = () => {
  const { sessionId } = useParams();

  const [sessionState, setSessionState] = useState<{
    loading: boolean;
    error: any;
    data: SessionType | null;
  }>({ loading: true, error: null, data: null });

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(customFirestore, "sessions", sessionId!),
      (snapshot) => {
        if (!snapshot.exists()) {
          throw new Error("Session doesn't exist");
        }
        setSessionState((prev) => ({
          ...prev,
          loading: false,
          data: { ...(snapshot.data() as SessionType) },
        }));
      }
    );
    return () => {
      unsubscribe();
    };
  }, [sessionId]);

  if (sessionState.loading) {
    return (
      <SpinnerWrapper>
        <CircularProgress />
      </SpinnerWrapper>
    );
  }

  return (
    <PageWrapper>
      <Content session={sessionState.data!}></Content>
      <Info session={sessionState.data!} />
    </PageWrapper>
  );
};

const SpinnerWrapper = styled.div`
  display: grid;
  place-content: center;
  height: 100%;
`;

const PageWrapper = styled.div`
  display: grid;
  grid-template-areas: "content info";
  grid-template-columns: 5fr 2.5fr;
  grid-template-rows: minmax(0, 1fr);
  height: 100%;
  gap: 12px;
  padding: 12px;
`;

export default Session;
