import { escapeForRegEx, Range } from "@tiptap/core";
import { ResolvedPos } from "@tiptap/pm/model";

export interface Trigger {
  chars: string[];
  oneCharTrigger: boolean;
  breakRegexWords: string[];
  allowSpaces: boolean;
  allowedPrefixes: string[] | null;
  startOfLine: boolean;
  $position: ResolvedPos;
}

export type SuggestionMatch = {
  range: Range;
  query: string;
  text: string;
} | null;

export function findSuggestionMatch(config: Trigger): SuggestionMatch {
  const {
    breakRegexWords = [],
    chars,
    allowSpaces,
    allowedPrefixes,
    startOfLine,
    $position,
    oneCharTrigger = true,
  } = config;

  // Join the characters into a character group for the regex
  const separatedEscapedChars = chars.map(escapeForRegEx);
  const escapedChars = separatedEscapedChars.join("");
  const suffix = new RegExp(`\\s[${escapedChars}]$`);
  const prefix = startOfLine ? "^" : "";
  const regexp = new RegExp(
    `${prefix}(?:^)?${
      oneCharTrigger
        ? `[${escapedChars}]`
        : `(${separatedEscapedChars.join("|")})`
    }${
      breakRegexWords.length > 0
        ? `(?!${breakRegexWords.join("|")})[a-z]*`
        : `[a-z]*`
    }`,
    "gm"
  );

  const text = $position.nodeBefore?.isText && $position.nodeBefore.text;

  if (!text) {
    return null;
  }

  const textFrom = $position.pos - text.length;
  const match = Array.from(text.matchAll(regexp)).pop();

  if (!match || match.input === undefined || match.index === undefined) {
    return null;
  }

  // JavaScript doesn't have lookbehinds. This hacks a check that first character
  // is a space or the start of the line
  const matchPrefix = match.input.slice(
    Math.max(0, match.index - 1),
    match.index
  );
  const matchPrefixIsAllowed = new RegExp(
    `^[${allowedPrefixes?.join("")}\0]?$`
  ).test(matchPrefix);

  if (allowedPrefixes !== null && !matchPrefixIsAllowed) {
    return null;
  }

  const from = textFrom + match.index;
  let to = from + match[0].length;

  if (allowSpaces && suffix.test(text.slice(to - 1, to + 1))) {
    match[0] += " ";
    to += 1;
  }

  if (from < $position.pos && to >= $position.pos) {
    return {
      range: {
        from,
        to,
      },
      query: match[0],
      text: match[0],
    };
  }

  return null;
}
