import React, { useState } from "react";

import FormDialog from "../components/invitesDialog";
import { getMembers } from "../utils/firebase_operations";
import { MemberWithStatus } from "../utils/types";
import { MembersTable, PlainCard } from "./settings_components";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { collection, doc, writeBatch } from "firebase/firestore";
import { customFirestore } from "../firebase";
import { useSetAtom } from "jotai";
import { customAlertStateAtom } from "./custom-alert";
import { CircularProgress } from "@mui/material";

const InvitesPanel = ({ companyId }: { companyId: string }) => {
  const [members, setMembers] = useState<MemberWithStatus[]>([]);
  const queryClient = useQueryClient();
  const setCustomAlertState = useSetAtom(customAlertStateAtom);

  useQuery({
    queryKey: ["INVITES", companyId],
    queryFn: async () => {
      const membersWithStatus = await getMembers(companyId);

      setMembers(membersWithStatus);
      return true;
    },
    refetchOnWindowFocus: false,
  });

  const addMembers = useMutation({
    mutationFn: async (validEmails: string[]) => {
      const batch = writeBatch(customFirestore);
      const newValidEmails = validEmails.filter(
        (validEmail) =>
          members.filter((member) => member.email === validEmail).length === 0
      );

      if (newValidEmails.length === 0) {
        setCustomAlertState({
          open: true,
          type: "warning",
          description: "this is just a description test",
          title: "No new emails to invite",
        });
      } else {
        newValidEmails.forEach((email) => {
          const docRef = doc(collection(customFirestore, "invitations"));
          batch.set(docRef, {
            email: email.toLowerCase(),
            organisationId: companyId,
          });
        });
        await batch.commit();
        // await Promise.all(
        //   newValidEmails.map((email) => handleEmailSending(email))
        // );
        setCustomAlertState({
          open: true,
          type: "success",
          title: `Successfully invited emails: [${validEmails.length}]`,
        });

        await queryClient.invalidateQueries({
          queryKey: ["INVITES", companyId],
        });
      }
    },
  });

  return (
    <PlainCard>
      <div className="grid grid-cols-2">
        <h1 className="text-3xl pl-4 pb-2 font-bold">Members </h1>
        <div className="place-self-end pr-2">{FormDialog(addMembers)}</div>
      </div>
      <div className="pt-4 pl-4 pb-8 pr-8 overflow-auto h-[calc(100%-50.25px)]">
        {members.length === 0 ? (
          <div className={"flex justify-center items-center h-full"}>
            <CircularProgress />
          </div>
        ) : (
          MembersTable(members)
        )}
      </div>
    </PlainCard>
  );
};

export default InvitesPanel;
