import React, {
  type Dispatch,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from "react";
import { kLanguages, kOrientations } from "../constants/appetize-constants";
import { AppetizeOSVersions } from "../utils/types";
import { AuthContext } from "../auth/AuthContext";
import { TOAST_OPTIONS, trackSentryException } from "../utils/helpers";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { customFirestore } from "../firebase";
import { Form, Formik } from "formik";
import { number, object, string, ValidationError } from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ArrowBack, Check } from "@mui/icons-material";
import FormikTextInput from "../formikComponents/formik-text-input";
import FormikSelect from "../formikComponents/formik-select";

interface DeviceConfigurationDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  appetizeOSVersions: AppetizeOSVersions;
}

const TestDeviceConfigurationDialog = ({
  open,
  setOpen,
  appetizeOSVersions,
}: DeviceConfigurationDialogProps) => {
  const { user } = useContext<any>(AuthContext);

  const handleClose = useCallback(() => {
    setOpen((prev) => false);
  }, [setOpen]);

  const queryClient = useQueryClient();

  const confirmHandler = useMutation({
    mutationFn: async (values: any) => {
      try {
        await addDoc(
          collection(customFirestore, "custom-test-device-configurations"),
          {
            ...values,
            organisationId: user.companyId,
          }
        );

        await queryClient.invalidateQueries({
          queryKey: ["getTestDeviceConfigurations"],
        });
        toast.success(`Configuration created successfully!`, TOAST_OPTIONS);
      } catch (e) {
        trackSentryException(e);
        toast.error(`An error occurred`, {
          ...TOAST_OPTIONS,
          autoClose: false,
        });
      }
      handleClose();
    },
  });

  const initialValues = useMemo(() => {
    const platforms = Object.keys(appetizeOSVersions);
    const initialPlatform = platforms.at(0)!;
    const initialDevice = Object.keys(appetizeOSVersions[initialPlatform]).at(
      0
    )!;
    const initialOS = appetizeOSVersions[initialPlatform][initialDevice].at(-1);

    return {
      coordinates: { latitude: null, longitude: null },
      platform: initialPlatform,
      device: initialDevice,
      osVersion: initialOS,
      locale: "en_US",
      orientation: kOrientations.at(0),
    };
  }, [appetizeOSVersions]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"sm"} fullWidth>
      <Formik
        initialValues={initialValues}
        validationSchema={object()
          .shape({
            coordinates: object().shape({
              longitude: number()
                .nullable()
                .typeError("Longitude must be a number"),
              latitude: number()
                .nullable()
                .typeError("Latitude must be a number"),
            }),
            platform: string().nonNullable(),
            device: string().nonNullable(),
            osVersion: string().nonNullable(),
            locale: string().nonNullable(),
            orientation: string().nonNullable(),
          })
          .test(
            "longitude-and-latitude",
            "Longitude and Latitude should both be provided if one is given",
            function (obj) {
              const { longitude, latitude } = obj.coordinates;
              if ((longitude && !latitude) || (latitude && !longitude)) {
                return new ValidationError(
                  "Longitude and Latitude should both be provided if one is given",
                  null,
                  longitude != null
                    ? "coordinates.latitude"
                    : "coordinates.longitude"
                );
              }
              return true; // return true if validation passes
            }
          )}
        onSubmit={async (values, { setSubmitting }) => {
          const coordinates =
            values.coordinates?.longitude != null &&
            values.coordinates?.latitude != null
              ? {
                  latitude: parseFloat(values.coordinates.latitude),
                  longitude: parseFloat(values.coordinates.longitude),
                }
              : undefined;

          let valuesToUse: any = values;

          if (coordinates == null) {
            const { coordinates, ...valuesWithoutCoordinates } = values;
            valuesToUse = valuesWithoutCoordinates;
          }
          await confirmHandler.mutateAsync(valuesToUse);
          setSubmitting(false);
        }}
        validateOnBlur={false}
      >
        {({ isSubmitting, values, setValues }) => (
          <Form>
            <DialogTitle>Add Test Device Configuration</DialogTitle>
            <DialogContent dividers className={"flex items-start gap-4"}>
              <div className={"flex flex-1 flex-col gap-4"}>
                <FormikSelect
                  name={"platform"}
                  key={"platform"}
                  label={"Platform"}
                  enableCapitalize={false}
                  options={Object.keys(appetizeOSVersions)}
                  onChange={(value: string) => {
                    setValues((prev) => {
                      const newDevice = Object.keys(
                        appetizeOSVersions[value]
                      ).at(0)!;
                      const newOS = appetizeOSVersions[value][newDevice].at(0);
                      return {
                        ...prev,
                        platform: value,
                        device: newDevice,
                        osVersion: newOS,
                      };
                    });
                  }}
                />
                <FormikSelect
                  name={"device"}
                  key={`${values.platform}-device`}
                  enableCapitalize={false}
                  label={"Device"}
                  options={Object.keys(appetizeOSVersions[values.platform])}
                  onChange={(value: string) => {
                    setValues((prev) => {
                      const newOS =
                        appetizeOSVersions[prev.platform][value].at(-1);
                      return {
                        ...prev,
                        device: value,
                        osVersion: newOS,
                      };
                    });
                  }}
                />
                <FormikSelect
                  name={"osVersion"}
                  key={`${values.platform}-${values.device}-os`}
                  label={"OS Version"}
                  options={
                    appetizeOSVersions[values.platform][values.device] ??
                    appetizeOSVersions[values.platform][
                      Object.keys(appetizeOSVersions[values.platform]).at(0)!
                    ]
                  }
                />
                <FormikSelect
                  name={"locale"}
                  key={"locale"}
                  label={"Language"}
                  options={kLanguages}
                  enableCapitalize={false}
                />
              </div>
              <div className={"flex flex-1 flex-col gap-[19px]"}>
                <FormikTextInput
                  label="Latitude"
                  name="coordinates.latitude"
                  type="text"
                  size={"small"}
                />
                <FormikTextInput
                  label="Longitude"
                  name="coordinates.longitude"
                  type="text"
                  size={"small"}
                />
                <FormikSelect
                  name={"orientation"}
                  key={"orientation"}
                  label={"Orientation"}
                  options={kOrientations}
                  enableCapitalize={false}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={handleClose}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
              <Button
                type={"submit"}
                disabled={isSubmitting}
                variant={"contained"}
                startIcon={<Check />}
              >
                Confirm
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default TestDeviceConfigurationDialog;
